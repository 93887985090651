import Vue from 'vue';
import { VueMaskDirective } from "v-mask";
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';
import router from './router';
import store from './store';
import NoSidebar from './layouts/NoSidebar';
import BlankLayout from './layouts/BlankLayout';

import App from './App.vue';
import vuetify from './plugins/vuetify';
Vue.directive("mask", VueMaskDirective);
Vue.component('no-sidebar-layout', NoSidebar);
Vue.component('no-sidebar-layout', NoSidebar);
Vue.component('blank-layout', BlankLayout);
Vue.config.productionTip = false;

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app');
