<template>
     <v-hover
        v-slot="{ hover }"
        open-delay="200"
      >
        <v-card @click="goToLink(link.to)" :elevation="hover ? 4 : 0" shaped to="/" outlined flat class="border-md" :class="{ 'on-hover': hover }">
            <v-card-title class="card-header text-center text-lg-left">
                <div class="d-flex rounded-lg align-center card-header-icon" style="width: 40px; height: 40px; align-items: center; justify-content: center;">
                    <v-icon color="secondary">{{ link.icon }}</v-icon>
                </div>
                <span class="ml-2">{{ link.text }}</span>
            </v-card-title>
            <div class="p-10 d-flex flex-lg-row flex-column">
                <div class="d-flex justify-center ma-1">
                    <v-img v-if="link.image" :src="link.image"  width="300"></v-img>
                    <v-img width="300" v-else src="/media/images/default.png" />
                </div>
                <div class="d-flex flex-column">
                    <div v-html="link.description" class="pa-2 flex-grow-1">
                    </div>
                </div>
            </div>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    props: {
        link: Object
    },
    methods: {
        goToLink(to) {
            console.log(to)
            this.$router.push(to)
        }
    }
}
</script>

<style lang="sass" scoped>
.v-card.on-hover
  background-color: rgba(#196bed, 0.1)
  >.v-card__text
    color: #000

.card-header
    background: rgba(#196bed, 0.1)
    color: #000
    border-bottom: 1px solid rgba(#196bed, 0.2)

.card-header-icon
    background: #FFF
    color: #000
    border: 1px solid rgba(#196bed, 0.4)

.on-hover > .card-header
    background: rgba(#196bed, 0.2)
    color: #196bed

.on-hover > .card-header-icon
    background: rgba(#196bed, 0.8)
    color: #196bed
</style>