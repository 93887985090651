<template>
  <v-menu offset-y v-if="user !== null">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" :to="`/usermanager/user/${user.general.user_id}`">
        <v-avatar size="32px" item class="mr-2">
          <v-img
            :src="`https://images.cryptocompare.com/${user.general.avatar_url}`"
            :alt="user.general.username"
          />
        </v-avatar>
        {{user.general.username}}
      </v-btn>
    </template>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderLogin",
  computed: {
    ...mapGetters("auth", ["user", "loadingUser", "errorUser"]),
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
</style>
 