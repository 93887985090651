import Vue from "vue";
import { SEARCH_USER } from "./actions.type";
import { SET_SEARCH_RESULTS, SET_LOADING } from "./mutations.type";
import { UserManagement } from "@/common/api.service";

const initialValues = {
    userSearch: null,
    isLoading: false
}
const state = {
    ...initialValues
};
const getters = {
    userSearch: (state) => state.userSearch
};
const mutations = {
    [SET_SEARCH_RESULTS]: (state, payload) => {
        Vue.set(state, 'userSearch', payload);
    },
    [SET_LOADING]: (state, payload) => {
        Vue.set(state, 'isLoading', payload);
    },
};

const actions = {
    [SEARCH_USER]: ({ commit }, {searchString, requestType}) => {
        console.log(searchString, requestType)
        UserManagement[requestType](
            searchString
        ).then(result => {
            commit(SET_SEARCH_RESULTS, result.data.Data);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};