import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth/index";
import snackbar from "./modules/snackbar";
import coins from "./modules/coins/index";
import blockExplorers from "./modules/blockExplorers/index"
import assetsManagement from "./modules/assetsManagement/index";
import exchangeManagement from "./modules/exchangeManagement/index"
import settings from "./modules/settings/index";
import indexComposition from "./modules/indexComposition/index";
import instrumentMap from "./modules/instrumentMap/index";
import userManager from "./modules/userManager/index";
import adManager from "./modules/adManager/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    settings,
    coins,
    blockExplorers,
    assetsManagement,
    exchangeManagement,
    indexComposition,
    instrumentMap,
    userManager,
    adManager,
  },
  plugins: [
  ]
});
