<template>
    <v-progress-circular
        :size="100"
        :width="7"
        color="primary"
        indeterminate
        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
    >
        <span class="overline primary--text">{{ text ? text : 'Loading' }}</span>
    </v-progress-circular>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: ["text"]
}
</script>