import Vue from 'vue';
import Vuex from 'vuex';
import {
    SETTINGS_DRAWER_TOGGLE_VISIBILITY
} from './mutations.type'

import {
    DRAWER_LEFT_TOGGLE
} from './actions.type'

Vue.use(Vuex);

const state = {
    drawerLeft: {
        visibility: true
    }
};

const getters = {
    drawerLeft(state) {
        return  state.drawerLeft;
    },
    drawerLeftIsVisible(state) {
        return state.drawerLeft.visibility;
    }
};

const mutations = {
    [SETTINGS_DRAWER_TOGGLE_VISIBILITY](state) {
        state.drawerLeft = {
            ...state.drawerLeft,
            visibility: !state.drawerLeft.visibility
        };
    }
}

const actions = {
    [DRAWER_LEFT_TOGGLE]({ commit }) {
        commit(SETTINGS_DRAWER_TOGGLE_VISIBILITY);
    },
};

const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default store;
