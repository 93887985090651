export const APPEND_NEW_PROP = 'appendNewProp';
export const TOGGLE_REMOVE_PROP = 'toggleRemoveProp';

export const DRY_RUN_INITIAL = 'BEDruRunInitial';
export const PUBLISH_ACTION_DELETESOURCE_SUCCESS = 'publishActionDeletedSourceSuccess';
export const PUBLISH_ACTION_NEWPROP_SUCCESS = 'publishActionNewPropSuccess';
export const PUBLISH_ACTION_DELETEPROP_SUCCESS = 'publishActionDeletePropSuccess';

export const FETCH_LOG_LOADING = 'fetchLogLoading';
export const FETCH_LOG_SUCCESS = 'fetchLogSuccess';
export const FETCH_LOG_ERROR = 'fetchLogError';
export const TOGGLE_LOG_EXPAND = 'toggleLogExpand';

export const NEW_SOURCE_OPTIONS_TOGGLE = 'newSourceOptionsExpand';
export const ADD_SOURCE_OPTION_ITEM = 'addSourceOptionsItem';
export const DELETE_SOURCE_OPTION_ITEM = 'deleteSourceOptionsItem';
export const SET_NEW_REQUEST_TYPE = 'setNewRequestType';

export const FETCH_LOG_GENERAL_LOADING = 'fetchLogGeneralLoading';
export const FETCH_LOG_GENERAL_SUCCESS = 'fetchLogGeneralSuccess';
export const FETCH_LOG_GENERAL_ERROR = 'fetchLogGeneralError';

export const SOURCE_DIRTY = 'sourceDirty';
export const SOURCE_PRISTINE = 'sourcePristine';

export const PROP_DIRTY = 'propDirty';
export const PROP_PRISTINE = 'propPristine';

export const SET_OPTIONS_LOADING = 'setOptionsLoading';
export const SET_OPTIONS_SUCCESS = 'setOptionsSuccess';
export const SET_OPTIONS_ERROR = 'setOptionsError';

export const SAVE_MAPPING_LOADING = 'saveMappingLoading';
export const SAVE_MAPPING_SUCCESS = 'saveMappingSuccess';
export const SAVE_MAPPING_ERROR = 'saveMappingError';

export const SET_VUEX_PROP = 'setVuexProp';
export const ADD_NEW_SOURCE_SET_ID = 'addNewSourceSetId';
export const ADD_NEW_SOURCE_LOADING = 'addNewSourceLoading';
export const ADD_NEW_SOURCE_ERROR = 'addNewSourceError';
export const ADD_NEW_SOURCE_SUCCESS = 'addNewSourceSuccess';

export const FETCH_EXCHANGE_LOADING = 'fetchExchangeLoading';
export const FETCH_EXCHANGE_SUCCESS = 'fetchExchangeSuccess';
export const FETCH_EXCHANGE_ERROR = 'fetchExchangeError';
export const PURGE_COIN = 'purgeCoin';

export const PROCESS_DATA_SINGLE_SOURCE_LOADING = 'processDataSingleSourceLoading';
export const PROCESS_DATA_SINGLE_SOURCE_SUCCESS = 'processDataSingleSourceSuccess';
export const PROCESS_DATA_SINGLE_SOURCE_ERROR = 'processDataSingleSourceError';
export const SET_SOURCES_ORIGINAL = 'setSourcesOriginal';

export const FETCH_LOG_ACTIVITY_LOADING = 'fetchLogActivityLoading';
export const FETCH_LOG_ACTIVITY_SUCCESS = 'fetchLogActivitySuccess';
export const FETCH_LOG_ACTIVITY_ERROR = 'fetchLogActivityError';
