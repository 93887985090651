<template>
    <div class="home">
       <Dashboard />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Dashboard from '@/components/Dashboard';

export default {
    name: 'Home',
    computed: {
        ...mapGetters('auth', ['user']),
    },
    components: {
        Dashboard
    }
};
</script>

<style>
@media (min-width: 1264px) {
    .container {
        max-width: 100% !important;
    }
}
@media (min-width: 960px) {
    .container {
        max-width: 100% !important;
    }
}
</style>

<style scoped>
.brand-image-light {
    width: 200px;
}
</style>