// blockExplorer.js
export const FETCH_BLOCKEXPLORERS = 'fetchBlockExplorers';
export const RESET_BLOCKEXPLORERS = 'resetBlockExplorers';

export const RESET_BLOCKEXPLORER_SOURCES = 'purgeBlockExplorerSources';
export const SUBMIT_NEW_SOURCE = 'submitNewSource';
export const CHANGE_BE_SOURCE_PROP_INDIVIDUAL = 'changeBESourcePropIndividual';
export const RESET_NEW_SOURCE = 'resetNewSource';
export const FETCH_SOURCE_DATA = 'fetchSourceData';
export const STATIC_BE_SOURCE = 'staticBESource';
export const ADD_NEW_SOURCE = 'addNewSource';
export const ADD_NEW_SOURCE_STATIC = 'addNewSourceStatic';
export const CHANGE_NEW_SOURCE_URL = 'changeNewSourceURL';


export const SELECT_BLOCKEXPLORER_COIN = 'selectBlockExplorerCoin';
export const INIT_BLOCKEXPLORER = 'initBlockExplorer';
export const FETCH_BLOCKEXPLORER = 'fetchBlockExplorer';

export const DRY_RUN = 'DryRun';
export const PROCESS_BE_PUBLISH_SOURCES = 'processBEPublishSources';
export const UPDATE_BE_COIN = 'updateBECoin';
export const PROCESS_PUBLISH_SOURCES = 'processBEPublishSources';
export const UPDATE_COIN = 'updateCoin';

export const TOGGLE_DELETE_PROP = 'toggleDeleteProp';
export const TOGGLE_DELETE_SOURCE = 'toggleDeleteSource';
export const ADD_NEW_PROP = 'addNewProp';
export const DELETE_PROP = 'deleteProp';
export const FETCH_SOURCES = 'fetchSources';
export const UPDATE_BLOCKEXPLORER = 'updateBlockExplorer';

export const FETCH_LOG = 'fetchLog';
export const TOGGLE_LOG = 'toggleLog';
export const TOGGLE_SOURCE_OPTIONS = 'toggleSourceOptions';
export const ADD_SOURCE_OPTION = 'addSourceOption';
export const DELETE_SOURCE_OPTION = 'deleteSourceOption';

export const FETCH_LOG_GENERAL = 'fetchLogGeneral';
export const UPDATE_SOURCES = 'updateSources';
export const SOURCE_MAKE_PRISTINE = 'sourceMakePristine';
export const SOURCE_MAKE_DIRTY = 'sourceMakeDirty';
export const PROP_MAKE_PRISTINE = 'propMakePristine';
export const PROP_MAKE_DIRTY = 'propMakeDirty';
export const FETCH_OPTIONS = 'fetchOptions';

export const SAVE_MAPPING = 'saveMapping';
export const SAVE_SOURCE = 'saveSource';

export const CHANGE_VUEX_PROP = 'changeVuexProp';

export const FETCH_ASSET = 'fetchAsset';
export const FETCH_ASSET_UTILITIES = 'fetchAssetUtilities';
export const RESET_COIN = 'resetCoin';

export const PROCESS_DATA_SINGLE_SOURCE = 'processDataSingleSource';
export const FETCH_TOP_MARKETCAP = 'fetchTopMarketCap';
export const FETCH_LOG_ACTIVITY = 'fetchLogActivity';