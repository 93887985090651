<template>
  <v-app id="app">
    <component :is="layout">
      <router-view />
      <Snackbar />
    </component>
  </v-app>
</template>
<script>
const default_layout = "blank";

import Vue from "vue";
import Meta from "vue-meta";
import Snackbar from "@/components/Common/Snackbar";
import VueCookies from "vue-cookies"

Vue.use(Meta);
Vue.use(VueCookies)

export default {
  components: {
    Snackbar
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
@media (min-width: 1904px) {
  .v-content__wrap > .container {
    max-width: 1400px;
  }
}
</style>
