import Vue from 'vue';
import Vuex from 'vuex';
import {
    AssetsManagementService
} from "@/common/api.service";

import {
    SET_OPTIONS_LOADING,
    SET_OPTIONS_SUCCESS,
    SET_OPTIONS_ERROR,
} from './mutations.type'

import {
    FETCH_OPTIONS,
} from './actions.type'

Vue.use(Vuex);
const appVersion = '0.1';

const intialValues = {
    appVersion: appVersion,
    options: null,
    loadingOptions: false,
    errorOptions: null
};

const state = {
    ...JSON.parse(JSON.stringify(intialValues))
};

const getters = {
    options: state => {
        return state.options;
    },
    loadingOptions: state => {
        return state.loadingOptions;
    },
    errorOptions: state => {
        return state.errorOptions;
    },
};

const mutations = {
    [SET_OPTIONS_LOADING]() {
        Vue.set(state, 'loadingOptions', true);
    },
    [SET_OPTIONS_SUCCESS](state, options) {
        Vue.set(state, 'options', options);
        Vue.set(state, 'loadingOptions', false);
    },
    [SET_OPTIONS_ERROR](state, error) {
        Vue.set(state, 'loadingOptions', false);
        Vue.set(state, 'errorOptions', error);
    },
};

const actions = {
    [FETCH_OPTIONS]({
        commit
    }) {
        return new Promise((resolve, reject) => {
            commit(SET_OPTIONS_LOADING);
            AssetsManagementService.getOptions()
                .then(({
                    data
                }) => {
                    const options = data.Data;
                    
                    let flatFieldsObject = {};
                    for (let group of options.ASSET_FIELD_GROUPS) {
                        for (let field of group.Fields) {
                            flatFieldsObject[field.Id] = field;
                        }
                    }
                    options._FIELDS_FLAT = flatFieldsObject;
                    commit(SET_OPTIONS_SUCCESS, options);
                    resolve(options);
                })
                .catch(error => {
                    commit(SET_OPTIONS_ERROR, error.response);
                    reject(error);
                });
        });
    },
};

const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default store;
