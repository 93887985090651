<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app dark color="primary" id="tools-header">
        <v-app-bar-nav-icon @click.stop="toggleDrawerLeft" />
        <div class="nav-logo"></div>
        <v-breadcrumbs :items="breadcrumbs" class="mt-2">
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item :disabled="item.disabled">
                    <router-link style="text-decoration: none; color: inherit" :to="item.path">
                        <strong>{{ item.text.toUpperCase() }}</strong>
                    </router-link>
                </v-breadcrumbs-item>
            </template>
            <template v-slot:divider>
                <v-icon>fas fa-caret-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-spacer />
        <HeaderLogin />
    </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DRAWER_LEFT_TOGGLE } from '@/store/modules/settings/actions.type';
import HeaderLogin from './HeaderLogin';

export default {
    name: 'Header',
    props: {
        source: String,
    },
    components: {
        HeaderLogin,
    },
    data: () => ({
        dialog: false,
        drawer: null,
    }),
    computed: {
        ...mapGetters('settings', ['drawerLeft', 'drawerLeftIsVisible']),
        breadcrumbs() {
            let breadcrumbs = new Array();
            let currentHref = new Array();

            let route = this.$route.path.split('/');

            breadcrumbs.push({
                text: 'Home',
                path: '/',
            });

            route.forEach((crumb, index) => {
                if (crumb) {
                    currentHref.push('/' + crumb);
                    breadcrumbs.push({
                        text: crumb,
                        disabled: index !== route.length - 1 ? false : true,
                        path: currentHref.join(''),
                    });
                }
            });

            return breadcrumbs;
        },
    },
    methods: {
        ...mapActions('settings', [DRAWER_LEFT_TOGGLE]),
        toggleDrawerLeft() {
            this.$store.dispatch(`settings/${DRAWER_LEFT_TOGGLE}`, null, {
                root: true,
            });
        },
    },
};
</script>

<style scoped>
.nav-logo {
    float: left;
    background: url(https://www.cryptocompare.com/media/44051315/logo.png) 0 0 no-repeat;
    background-size: 90%;
    background-position: center;
    width: 167px;
    height: 28px;
}

.page-name {
    font-weight: bold;
}

.page-name-slash {
    margin-right: 5px;
}
#tools-header {
    z-index: 100;
}
</style>
 