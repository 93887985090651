import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import colors from 'vuetify/lib/util/colors';
Vue.use(Vuetify);


export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#0C1B37',
                secondary: '#186AED',
                accent: '#00D8C7'
            },
            dark: {
                background: '#00000',
                primary: '#0A1623'
            }
        },
    },
});
