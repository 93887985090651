<template>
    <v-navigation-drawer :value="drawerLeftIsVisible" :clipped="$vuetify.breakpoint.lgAndUp" app dark color="primary" expand-on-hover fixed>
        <v-list dense>
            <v-list-item v-for="item in items" :key="item.text" link :to="item.to">
                <v-list-item-action>
                    <v-icon small>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'DrawerLeft',
    props: {
        source: String,
    },
    data: () => ({
        drawer: null,
        items: [
            { icon: 'fa-th', text: 'Dashboard', to: '/' },
            { icon: 'fa-wallet', text: 'Assets Management', to: '/assets/list' },
            { icon: 'fa-exchange-alt', text: 'Exchange Management', to: '/exchanges/list' },
            { icon: 'fa-server', text: 'Exchange Discovery Service', to: '/exchange-discovery-service' },
            { icon: 'far fa-image', text: 'Ad Campaign Manager', to: '/adcampaignmanager' },
            { icon: 'fa-image', text: 'Ad Manager', to: '/admanager' },
            { icon: 'fa-indent', text: 'Index Composition', to: '/indexcomposition' },
            { icon: 'fa-map-signs', text: 'Instrument Map', to: '/instrumentmap' },
            { icon: 'fa-user', text: 'User Management', to: '/usermanager' },
            { icon: 'fa-chart-area', text: 'Insights', to: '/insights' },
            { icon: 'fa-download', text: 'Data API Helpers', to: '/data-api-helpers' },
            { icon: 'fa-newspaper', text: 'News Manager', to: '/news-manager' },
            { icon: 'fa-life-ring', text: 'QA Data', to: '/qa-data' },
            { icon: 'fa-user', text: 'Asset Contact Manager', to: '/asset/contact-manager' },
        ],
    }),
    computed: {
        ...mapGetters('settings', ['drawerLeft', 'drawerLeftIsVisible']),
    },
};
</script>