import Vue from 'vue';
import Vuex from 'vuex';
import {
    BlockExplorerService
} from "@/common/api.service";

import _ from 'lodash';

import {
    SET_OPTIONS_LOADING,
    SET_OPTIONS_SUCCESS,
    SET_OPTIONS_ERROR,
    FETCH_TOP_MARKETCAP_LOADING,
    FETCH_TOP_MARKETCAP_SUCCESS,
    FETCH_TOP_MARKETCAP_ERROR,
    FETCH_BLOCKEXPLORERS_LOADING,
    FETCH_BLOCKEXPLORERS_SUCCESS,
    FETCH_BLOCKEXPLORERS_ERROR,
    FETCH_LOG_ACTIVITY_LOADING,
    FETCH_LOG_ACTIVITY_SUCCESS,
    FETCH_LOG_ACTIVITY_ERROR
} from './mutations.type'

import {
    FETCH_OPTIONS,
    FETCH_ASSET_UTILITIES,
    FETCH_TOP_MARKETCAP,
    FETCH_BLOCKEXPLORERS,
    FETCH_LOG_ACTIVITY,
} from './actions.type'

Vue.use(Vuex);

const intialValues = {
    options: null,
    loadingOptions: false,
    errorOptions: false,
    coin: null,
    blockExplorers: null,
    loadingBlockExplorers: false,
    errorBlockExplorers: null,
    blockExplorer: {},
    loadingBlockExplorer: {},
    errorBlockExplorer: null,
    sourcesData: [],
    logGeneral: [],
    loadingLogGeneral: false,
    topMarketCap: [],
    errorTopMarketCap: null,
    loadingTopMarketCap: false,
    topMarketCapStats: null,
    dashboard: {
        logActivity: null,
        loadingLogActivity: null,
        errorLogActivity: null
    }
};

const state = {
    ...JSON.parse(JSON.stringify(intialValues))
};

const getters = {
    log(state) {
        return state.log;
    },
    isLoadingLog(state) {
        return state.loadingLog;
    },
    logGeneral(state) {
        return state.logGeneral;
    },
    isLoadingLogGeneral(state) {
        return state.loadingLogGeneral;
    },
    isLoadingSources(state) {
        return state.loadingSources;
    },
    errorSources(state) {
        return state.errorSources;
    },
    coin(state) {
        return state.coin;
    },
    source(state) {
        return state.source
    },
    sourcesData(state) {
        return state.sourcesData.sort(function (a, b) {
            return a.sort_order - b.sort_order
        });
    },
    coinProps(state, getters) {
        let values = {};
        for (let [mapping, props] of Object.entries(
            getters.mappingStats
        )) {
            values[mapping] = Object.values(props).sort(function (a, b) {
                return b.place - a.place
            })[0];
        }
        return values;
    },
    getSourceById: (state, getters) => id => {
        return getters.sources[id];
    },
    sourcesOriginal(state) {
        return state.sourcesOriginal
    },
    options: state => {
        return state.options;
    },
    getStateDeepValue: state => path => {
        return _.get(state, path);
    },
    mappingsForSymbol: state => symbol => {
        return state.blockExplorers[symbol].mapped_properties;
    },
    topMarketCap(state) {
        return state.topMarketCap;
    },
    topMarketCapStats(state) {
        return state.topMarketCapStats;
    },
    loadingTopMarketCap(state) {
        return state.loadingTopMarketCap;
    },
    errorTopMarketCap(state) {
        return state.errorTopMarketCap;
    },
    loadingLogActivity(state) {
        return state.dashboard.loadingLogActivity;
    },
    logActivity(state) {
        return state.dashboard.logActivity;
    },
    errorLogActivity(state) {
        return state.dashboard.errorLogActivity;
    },
    dashboard(state) {
        return state.dashboard;
    },
    blockExplorers(state) {
        return state.blockExplorers;
    },
    errorBlockExplorers(state) {
        return state.errorBlockExplorers;
    },
    
};

const mutations = {
    [SET_OPTIONS_LOADING]() {
        Vue.set(state, 'loadingOptions', true);
    },
    [SET_OPTIONS_SUCCESS](state, options) {
        Vue.set(state, 'options', options);
        Vue.set(state, 'loadingOptions', false);
    },
    [SET_OPTIONS_ERROR](state, error) {
        Vue.set(state, 'loadingOptions', false);
        Vue.set(state, 'errorOptions', error);
    },
    [FETCH_TOP_MARKETCAP_LOADING](state) {
        Vue.set(state, 'loadingTopMarketCap', true);
    },
    [FETCH_TOP_MARKETCAP_SUCCESS](state, payload) {
        Vue.set(state, 'topMarketCap', payload.list);
        Vue.set(state, 'errorTopMarketCap', false);
        Vue.set(state, 'loadingTopMarketCap', false);
        Vue.set(state, 'topMarketCapStats', payload.stats);
    },
    [FETCH_TOP_MARKETCAP_ERROR](state, error) {
        Vue.set(state, 'errorTopMarketCap', error);
        Vue.set(state, 'loadingTopMarketCap', false);
    },
    [FETCH_BLOCKEXPLORERS_LOADING](state) {
        //Vue.set(state, 'blockExplorers', {});
        Vue.set(state, 'loadingBlockExplorers', true);
    },
    [FETCH_BLOCKEXPLORERS_ERROR](state, error) {
        Vue.set(state, 'errorBlockExplorers', error);
    },
    [FETCH_BLOCKEXPLORERS_SUCCESS](state, blockExplorers) {
        Vue.set(state, 'blockExplorers', blockExplorers);
        Vue.set(state, 'loadingBlockExplorers', false);
    },
    [FETCH_LOG_ACTIVITY_LOADING](state) {
        Vue.set(state.dashboard, 'loadingLogActivity', true);
    },
    [FETCH_LOG_ACTIVITY_SUCCESS](state, payload) {
        Vue.set(state.dashboard, 'logActivity', payload);
    },
    [FETCH_LOG_ACTIVITY_ERROR](state) {
        Vue.set(state.dashboard, 'errorLogActivity', true);
    },
};

const actions = {
    [FETCH_OPTIONS]({
        commit
    }) {
        return new Promise((resolve, reject) => {
            commit(SET_OPTIONS_LOADING);
            BlockExplorerService.getOptions()
                .then(({
                    data
                }) => {
                    const options = data.Data;
                    commit(SET_OPTIONS_SUCCESS, options);
                    resolve(options);
                })
                .catch(error => {
                    commit(SET_OPTIONS_ERROR, error.response);
                    reject(error);
                });
        });
    },
    [FETCH_ASSET_UTILITIES]({ dispatch }) {
        dispatch(`blockExplorers/${FETCH_OPTIONS}`, null, { root: true });
    },
    [FETCH_TOP_MARKETCAP]({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit(FETCH_TOP_MARKETCAP_LOADING);
            return BlockExplorerService.getTopMarketCap(params).then(({ data }) => {
                const coins = data.Data.list;
                const stats = data.Data.stats;
                for (let i = 0; i < coins.length; i++) {
                    let currentCoin = coins[i];
                    let computed = currentCoin.computed = {};
                    let coinGecko = currentCoin.otherProviders.coinGecko;
                    let coinMarketCap = currentCoin.otherProviders.coinMarketCap;

                    const mktcapCC = currentCoin.CC.mktcap || 0;
                    const supplyCC = currentCoin.CC.supply || 0;
                    const totalVolume24hCC = currentCoin.CC.total_volume_24_hour || 0;
                    const mktcapLocal = currentCoin.CC.local_mktcap;
                    const supplyLocal = currentCoin.CC.local_supply;

                    const defaultObject = {
                        mktcap: null,
                        mktcap_difference: null,
                        mktcap_percentage: null,
                        supply: null,
                        supply_difference: null,
                        supply_percentage: null,
                        total_volume_24_hour: null,
                        total_volume_24_hour_difference: null,
                        total_volume_24_hour_percentage: null
                    };

                    computed.local = {
                        mktcap: null,
                        mktcap_difference: null,
                        mktcap_percentage: null,
                        supply: null,
                        supply_difference: null,
                        supply_percentage: null
                    };

                    computed.coinGecko = {
                        ...defaultObject
                    };

                    computed.coinMarketCap = {
                        ...defaultObject
                    };

                    // coinGecko
                    if (coinGecko.mktcap !== undefined) {
                        computed.coinGecko.mktcap = coinGecko.mktcap;
                        computed.coinGecko.mktcap_difference = coinGecko.mktcap - mktcapCC;
                        computed.coinGecko.mktcap_percentage = ((coinGecko.mktcap - mktcapCC) / mktcapCC) * 100;
                    }
                    if (coinGecko.supply !== undefined) {
                        computed.coinGecko.supply = coinGecko.supply;
                        computed.coinGecko.supply_difference = coinGecko.supply - supplyCC;
                        computed.coinGecko.supply_percentage = ((coinGecko.supply - supplyCC) / supplyCC) * 100;
                    }
                    if (coinGecko.total_volume_24_hour !== undefined) {
                        computed.coinGecko.total_volume_24_hour = coinGecko.total_volume_24_hour;
                        computed.coinGecko.total_volume_24_hour_difference = coinGecko.total_volume_24_hour - totalVolume24hCC;
                        computed.coinGecko.total_volume_24_hour_percentage = ((coinGecko.total_volume_24_hour - totalVolume24hCC) / totalVolume24hCC) * 100;
                    }

                    // coinMarketCap
                    if (coinMarketCap.mktcap !== undefined) {
                        computed.coinMarketCap.mktcap = coinMarketCap.mktcap;
                        computed.coinMarketCap.mktcap_difference = coinMarketCap.mktcap - mktcapCC;
                        computed.coinMarketCap.mktcap_percentage = ((coinMarketCap.mktcap - mktcapCC) / mktcapCC) * 100;
                    }
                    if (coinMarketCap.supply !== undefined) {
                        computed.coinMarketCap.supply = coinMarketCap.supply;
                        computed.coinMarketCap.supply_difference = coinMarketCap.supply - supplyCC;
                        computed.coinMarketCap.supply_percentage = ((coinMarketCap.supply - supplyCC) / supplyCC) * 100;
                    }
                    if (coinMarketCap.total_volume_24_hour !== undefined) {
                        computed.coinMarketCap.total_volume_24_hour = coinMarketCap.total_volume_24_hour;
                        computed.coinMarketCap.total_volume_24_hour_difference = coinMarketCap.total_volume_24_hour - totalVolume24hCC;
                        computed.coinMarketCap.total_volume_24_hour_percentage = ((coinMarketCap.total_volume_24_hour - totalVolume24hCC) / totalVolume24hCC) * 100;
                    }

                    if (mktcapLocal !== null && mktcapLocal !== undefined) {
                        computed.local.mktcap = mktcapLocal;
                        computed.local.mktcap_difference = mktcapLocal - mktcapCC;
                        computed.local.mktcap_percentage = ((mktcapLocal - mktcapCC) / mktcapCC) * 100;
                    }

                    if (supplyLocal !== null && supplyLocal !== undefined) {
                        computed.local.supply = supplyLocal;
                        computed.local.supply_difference = supplyLocal - supplyCC;
                        computed.local.supply_percentage = ((supplyLocal - supplyCC) / supplyCC) * 100;
                    }
                }

                commit(FETCH_TOP_MARKETCAP_SUCCESS, {
                    list: coins,
                    stats: stats
                });
                resolve(coins);
            }).catch(error => {
                console.log(error);
                commit(FETCH_TOP_MARKETCAP_ERROR, error);
                reject(error);
            });
        });
    },
    [FETCH_BLOCKEXPLORERS]({
        commit
    }) {
        return new Promise((resolve, reject) => {
            commit(FETCH_BLOCKEXPLORERS_LOADING);
            return BlockExplorerService.getBlockExplorers()
                .then(({
                    data
                }) => {
                    commit(FETCH_BLOCKEXPLORERS_SUCCESS, data.Data);
                    resolve(data.Data);
                })
                .catch(error => {
                    commit(FETCH_BLOCKEXPLORERS_ERROR, error);
                    reject(error);
                });
        });
    },
    [FETCH_LOG_ACTIVITY]({ commit }, payload) {
        commit(FETCH_LOG_ACTIVITY_LOADING);
        return BlockExplorerService.getLogDayActivity(payload).then(({ data }) => {
            commit(FETCH_LOG_ACTIVITY_SUCCESS, data.Data);
        }).catch(err => {
            commit(FETCH_LOG_ACTIVITY_ERROR, err);
        });
    }
};

const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default store;