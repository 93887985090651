import Vue from "vue";
import { SUBMIT_FORM, UPDATE_NAME } from "./actions.type";
import { SET_VALUE, SET_NAME } from "./mutations.type";

const initialValues = {
    info: {
        firstName: null,
        lastName: null,
        email: null
    }
}
const state = {
    ...initialValues
};
const getters = {
   info: (state) => state.info
};
const mutations = {
    [SET_VALUE]:(state, payload) => {
        Vue.set(state, 'info', payload);
    },
    [SET_NAME]:(state, payload) => {
        Vue.set(state.info, 'firstName', payload)
    }
};

const actions = {
    [SUBMIT_FORM]:({commit}, payload) => {
        commit(SET_VALUE, payload);
    },
    [UPDATE_NAME]:({commit}, payload) => {
        commit(SET_NAME, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};