<template>
    <div>
        <v-card>
            <v-card-title>
                <h1 class="display-1">Home</h1>
            </v-card-title>
            <v-card-text>
                <div>
                    <p>
                        The <b>Tools platform</b> serves as the <b>central hub</b> for accessing and managing all
                        core operational functions that drive our data
                        integrity, account management, <b>advertising</b>, and <b>analytics</b> efforts. Each tool
                        within this
                        platform is designed to streamline
                        specific processes, from curating asset data and configuring index methodologies to managing
                        advertising campaigns and standardizing exchange integrations. This organized suite enables
                        our teams—from
                        <b>Static Data Management and Integrations</b>
                        to <b>Sales</b>, <b>Support</b>, and <b>Ads</b> — to navigate seamlessly across functions,
                        ensuring data accuracy,
                        efficient workflows, and
                        customer satisfaction.
                    </p>
                    <p>
                        With easy access to tools like the <b>Asset Manager</b>, <b>Exchange Manager</b>, <b>Ad
                            Campaign Manager</b>, and <b>User Manager</b>,
                        the platform provides centralized control over asset and exchange data, user accounts, ad
                        campaigns, and more.
                        Other tools, such as <b>Insights</b> and <b>News Manager</b>, offer business-wide visibility
                        and organized news flow,
                        while the <b>Index Composition</b> and <b>Instrument Map</b> tools ensure consistent and
                        standardized data across indices
                        and exchanges. These Tools are an essential resource for teams, empowering them to maintain
                        high standards
                        and operational efficiency across our services.
                    </p>
                </div>
                <v-row align="start" no-gutters>
                    <v-col v-for="link in quickLinks" :key="link.text" xs="12" sm="12" md="6" xl="6" class="d-flex"
                        style="flex-direction:column">
                        <div class="pa-2 ma-2 flex-grow-1">
                            <Link :link="link">
                            </Link>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Link from "@/components/Dashboard/Link"

export default {
    data() {
        return {
            quickLinks: [
                { icon: 'fa-wallet', text: 'Assets Management', to: '/assets/list', description: 'Our <b>CMS for asset management</b>, the <b>Asset Manager</b>, serves as a central hub for <b>adding and organizing</b> both static and dynamic data on assets. It allows the <b>Static Data Management team</b> to input <b>static asset details</b> while seamlessly pulling dynamic data from third-party sources, such as <b>asset supply and the latest block information</b>. The <b>Asset Manager</b> is also critical in determining which on-chain assets to list, making it an essential tool for managing asset data accurately and efficiently.', image: '/media/images/asset-manager.png' },
                { icon: 'fa-exchange-alt', text: 'Exchange Management', description: '<b>The Exchange Manager</b> is our CMS dedicated to exchange integrations, used by both the Integrations team and the Static Data Management team. This tool centralizes the configuration and management of exchange integrations, allowing teams to add, update, and monitor integration data in a structured and consistent manner. It enables efficient management of exchange-related data, streamlining updates and ensuring data quality across all integrated exchanges.', to: '/exchanges/list', image: '/media/images/exchange-manager.png' },
                { icon: 'fa-user', text: 'User Management', description: 'The <b>User Manager</b> is our comprehensive <b>account management</b> tool used by sales and support teams to handle <b>customer accounts</b> effectively. It provides functionalities for setting up <b>roles, managing logins, and administering</b> all essential aspects of account management. With features tailored for supporting customers, the User Manager streamlines account access, role assignments, and user management, enabling efficient and responsive service for our clients.', to: '/usermanager', image: '/media/images/user-management.png' },
                { icon: 'far fa-image', text: 'Ad Campaign Manager', description: 'The <b>Ad Campaign Manager</b> is the primary tool for our ads teams to create, launch, and <b>manage advertising campaigns</b> across the main websites we oversee. It enables streamlined setup, scheduling, and <b>tracking</b> of <b>ad performance</b>, providing teams with the controls needed to optimize reach and engagement. This tool centralizes ad management, making it easier to align campaigns with business objectives and maximize the impact of our web properties.', to: '/admanager', image: '/media/images/ad-manager.png' },
                { icon: 'fa-map-signs', text: 'Instrument Map', description: 'The <b>Instrument Map</b> is a vital tool for standardizing and verifying instrument mappings across centralized and decentralized exchanges, covering spot, futures, options, and AMM products. By linking instruments to the correct assets, it ensures consistency and accuracy in our data across all exchanges. This tool is extensively used by the L1 Support team to verify that listed instruments are correctly associated with their respective assets, enhancing data integrity and supporting seamless integration across trading platforms.', to: '/instrumentmap', image: '/media/images/instrument-mapping.png' },
                { icon: 'fa-indent', text: 'Index Composition', description: 'The <b>Index Composition</b> tool is essential for managing and configuring the components of our most widely used <b>index methodologies</b>. It enables teams to set and adjust the composition rules, asset weightings, and other key parameters, ensuring indices are constructed in alignment with specific methodological standards. This tool allows for the flexible yet consistent maintenance of indices, supporting both transparency and accuracy in our index offerings', to: '/indexcomposition', image: '/media/images/index-composition.png' },
                { icon: 'fa-newspaper', text: 'News Manager', description: '<b>News Manager</b> is our dedicated tool for overseeing news sources and organizing content into relevant categories. It allows teams to manage, curate, and categorize news efficiently, ensuring that information is structured and accessible. This tool supports streamlined content management, enabling teams to keep news feeds organized and relevant for users.', to: '/news-manager', image: `/media/images/news-manager.png` },
            ]
        }
    },
    props: {

    },
    components: {
        Link
    },
    computed: {
    },
    methods: {

    }
}
</script>