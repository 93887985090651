import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const initialState = {
    text: "",
    color: "",
    timeout: "",
    top: false,
    bottom: true,
    left: false,
    right: true
}

const state = {
    ...initialState
};

const getters = {
    text: state => {
        return state.text;
    },
    color: state => {
        return state.color;
    },
    timeout: state => {
        return state.timeout;
    },
    top: state => {
        return state.top;
    },
    bottom: state => {
        return state.bottom;
    },
    left: state => {
        return state.left;
    },
    right: state => {
        return state.right;
    },
};

const mutations = {
    SHOW_MESSAGE(state, payload) {
        state.text = payload.text;
        state.color = payload.color;
        state.timeout = payload.timeout;
        state.top = payload.top || initialState.top,
        state.bottom = payload.bottom || initialState.bottom,
        state.left = payload.left || initialState.left,
        state.right = payload.right || initialState.right
    },
};

const actions = {
    showSnack({ commit }, payload) {
        commit("SHOW_MESSAGE", payload);
    },
}


const store = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default store;
