import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

import { FETCH_AUTH_USER } from '@/store/modules/auth/actions.type';

import store from '../store';

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Dashboard - CCAdmin',
            pageName: 'Dashboard',
            layout: 'no-sidebar',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: true,
        meta: {
            title: 'Dashboard - Log in required',
            pageName: 'Dashboard',
            layout: 'blank',
        },
    },
    {
        path: '/assets',
        name: 'AssetsManagement',
        component: () => import( /* webpackChunkName: "assetsManagement-home" */ '../views/AssetsManagement/Home/index.vue'),
        redirect: { name: 'assetsManagement.list' },
        children: [{
                path: 'list',
                name: 'assetsManagement.list',
                component: () => import( /* webpackChunkName: "assetsManagement-assets" */ '../views/AssetsManagement/Home/Assets.vue'),
                meta: {
                    title: 'Assets',
                    pageName: 'Assets',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: true,
                    showInParentNavigation: true,
                },
            },
            {
                path: 'deleted',
                name: 'assetsManagement.deleted',
                component: () => import( /* webpackChunkName: "assetsManagement-deleted" */ '../views/AssetsManagement/Home/AssetsDeleted.vue'),
                meta: {
                    title: 'Deleted',
                    pageName: 'Deleted',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: true,
                    showInParentNavigation: true,
                },
            },
            {
                path: 'issuer',
                name: 'assetsManagement.issuer',
                component: () => import( /* webpackChunkName: "assetsManagement-issuer" */ '../views/AssetsManagement/Home/AssetIssuer.vue'),
                meta: {
                    title: 'Issuer',
                    pageName: 'Issuer',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: true,
                    showInParentNavigation: true,
                },
            },
            {
                path: 'onchain/requests',
                name: 'assetsManagement.onchain',
                component: () => import( /* webpackChunkName: "assetsManagement-onchain" */ '../views/AssetsManagement/Home/OnChain.vue'),
                meta: {
                    title: 'On Chain',
                    pageName: 'On Chain Asset Requests',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: true,
                    showInParentNavigation: true,
                },
            },
            {
                path: 'list/CMC',
                name: 'assetsManagement.list.cmc',
                component: () => import( /* webpackChunkName: "assetsManagement-assets" */ '../views/AssetsManagement/Home/AssetsThirdParty.vue'),
                meta: {
                    title: 'CoinMarketCap',
                    pageName: 'Assets CoinMarketCap',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: true,
                    showInParentNavigation: true,
                },
            },
            {
                path: 'list/CG',
                name: 'assetsManagement.list.cg',
                component: () => import( /* webpackChunkName: "assetsManagement-assets" */ '../views/AssetsManagement/Home/AssetsThirdParty.vue'),
                meta: {
                    title: 'CoinGecko',
                    pageName: 'Assets CoinGecko',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: true,
                    showInParentNavigation: true,
                },
            },
            {
                path: 'activity',
                name: 'assetsManagement.activity',
                component: () => import( /* webpackChunkName: "assetsManagement-activity" */ '../views/AssetsManagement/Home/Activity.vue'),
                meta: {
                    title: 'User Activity',
                    pageName: 'User Activity',
                    layout: 'no-sidebar',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: true,
                    showInParentNavigation: true,
                },
            },
        ],
    },
    {
        path: '/assets/:symbol',
        name: 'AssetDetails',
        component: () => import( /* webpackChunkName: "assetsManagement-asset" */ '../views/AssetsManagement/AssetDetails'),
        props: true,
        meta: {
            title: 'Asset Info',
            pageName: 'Asset Management / Edit',
            layout: 'no-sidebar',
            transition: 'slide-left',
            requiresAuth: true,
            authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
            parentHeader: false,
            showInParentNavigation: false,
        },
        redirect: { name: 'AssetDetails_GENERAL' },
        children: [{
                path: 'GENERAL',
                name: 'AssetDetails_GENERAL',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-general" */ '../components/AssetsManagement/AssetDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'General Details',
                    Id: 'GENERAL',
                    pageName: 'Asset Management / General',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'BLOCKCHAIN_DATA',
                name: 'AssetDetails_BLOCKCHAIN_DATA',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-blockchain-data" */ '../components/AssetsManagement/AssetDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'Blockchain Information',
                    Id: 'BLOCKCHAIN_DATA',
                    pageName: 'Asset Management / Blockchain Data',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'SEO',
                name: 'AssetDetails_SEO',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-seo" */ '../components/AssetsManagement/AssetDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'Search Engine Optimisation',
                    Id: 'SEO',
                    pageName: 'Asset Management / Asset SEO',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'CONTACT',
                name: 'AssetDetails_CONTACT',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-contact" */ '../components/AssetsManagement/AssetDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'Associate Contacts',
                    Id: 'CONTACT',
                    pageName: 'Asset Management / Asset Contacts',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'TOKEN_SALE',
                name: 'AssetDetails_TOKEN_SALE',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-token-sales" */ '../components/AssetsManagement/AssetDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'Token Sales',
                    Id: 'TOKEN_SALE',
                    pageName: 'Asset Management / Asset Token Sales',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'DYNAMIC',
                name: 'AssetDetails_DYNAMIC',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-sources" */ '../views/AssetsManagement/AssetDetails/Sources.vue'),
                props: true,
                meta: {
                    title: 'Information Sources',
                    pageName: 'Asset Management / Edit / Dynamic',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'EVENTS',
                name: 'AssetDetails_EVENTS',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-events" */ '../components/AssetsManagement/AssetDetails/Asset/Events/index.vue'),
                props: true,
                meta: {
                    title: 'Events',
                    pageName: 'Asset Management / Edit / Events',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'HISTO',
                name: 'AssetDetails_HISTO',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-supply" */ '../views/AssetsManagement/AssetDetails/HistoSupply.vue'),
                props: true,
                meta: {
                    title: 'Historical Supply',
                    pageName: 'Asset Management / Edit / Supply',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'LOG',
                name: 'AssetDetails_LOG',
                component: () => import( /* webpackChunkName: "assetsManagement-asset-supply" */ '../components/AssetsManagement/AssetDetails/Log'),
                props: true,
                meta: {
                    title: 'Change Log',
                    pageName: 'Asset Management / Edit / Log',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['asset_management_viewer', 'asset_management_editor', 'asset_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
        ],
    },
    {
        path: '/exchanges',
        name: 'ExchangeManagement',
        component: () => import( /* webpackChunkName: "exchangeManagement-home" */ '../views/ExchangeManagement/Home/index.vue'),
        redirect: { name: 'exchangeManagement.list' },
        children: [{
                path: 'list',
                name: 'exchangeManagement.list',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchanges" */ '../views/ExchangeManagement/Home/Exchanges.vue'),
                meta: {
                    title: 'Exchanges',
                    pageName: 'Exchanges',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: true,
                    showInParentNavigation: true,
                },
            },
            {
                path: 'polling-frequencies',
                name: 'exchangeManagement.polling_frequencies',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchange-polling-frequencies" */ '../views/ExchangeManagement/PollingFrequencies/index.vue'),
                props: true,
                meta: {
                    title: 'Polling Frequencies',
                    pageName: 'Exchanges / Polling Frequencies',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: true,
                },
            },
        ],
    },
    {
        path: '/exchanges/:EXCHANGE_INTERNAL_NAME',
        name: 'ExchangeDetails',
        component: () => import( /* webpackChunkName: "exchangeManagement-exchange" */ '../views/ExchangeManagement/ExchangeDetails'),
        props: true,
        meta: {
            title: 'Exchange Info',
            pageName: 'Exchange Management / Edit',
            layout: 'no-sidebar',
            transition: 'slide-left',
            requiresAuth: true,
            authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
            parentHeader: false,
            showInParentNavigation: false,
        },
        redirect: { name: 'ExchangeDetails_GENERAL' },
        children: [{
                path: 'GENERAL',
                name: 'ExchangeDetails_GENERAL',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchange-general" */ '../components/ExchangeManagement/ExchangeDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'General Details',
                    Id: 'GENERAL',
                    pageName: 'Exchange Management / General',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'INTEGRATION',
                name: 'ExchangeDetails_INTEGRATION',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchange-integration" */ '../components/ExchangeManagement/ExchangeDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'Integration Details',
                    Id: 'INTEGRATION',
                    pageName: 'Exchange Management / Integration',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'BENCHMARK',
                name: 'ExchangeDetails_BENCHMARK',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchange-benchmark" */ '../components/ExchangeManagement/ExchangeDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'Benchmark Details',
                    Id: 'BENCHMARK',
                    pageName: 'Exchange Management / Benchmark',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'BUSINESS',
                name: 'ExchangeDetails_BUSINESS',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchange-business" */ '../components/ExchangeManagement/ExchangeDetails/FieldsList'),
                props: true,
                meta: {
                    title: 'Business Details',
                    Id: 'BUSINESS',
                    pageName: 'Exchange Management / Business',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'EVENTS',
                name: 'ExchangeDetails_EVENTS',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchange-events" */ '../components/ExchangeManagement/ExchangeDetails/Exchange/Events/index.vue'),
                props: true,
                meta: {
                    title: 'Events',
                    pageName: 'Exchange Management / Edit / Events',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'EDS',
                name: 'ExchangeDetails_EDS',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchange-eds" */ '../components/ExchangeManagement/ExchangeDetails/Exchange/ExchangeDiscoveryService/index.vue'),
                props: true,
                meta: {
                    title: 'Events',
                    pageName: 'Exchange Management / Edit / Exchannge Discovery Service',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
            {
                path: 'LOG',
                name: 'ExchangeDetails_LOG',
                component: () => import( /* webpackChunkName: "exchangeManagement-exchange-supply" */ '../components/ExchangeManagement/ExchangeDetails/Log'),
                props: true,
                meta: {
                    title: 'Change Log',
                    pageName: 'Exchange Management / Edit / Log',
                    layout: 'no-sidebar',
                    transition: 'slide-left',
                    requiresAuth: true,
                    authRoles: ['exchange_management_viewer', 'exchange_management_editor', 'exchange_management_admin'],
                    parentHeader: false,
                    showInParentNavigation: false,
                },
            },
        ],
    },

    {
        path: '/adcampaignmanager',
        name: 'AdCampaignManager',
        component: () => import('../views/AdCampaignManager/Home.vue'),
        meta: {
            title: 'Ad Manager',
            pageName: 'Ad Manager',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/adcampaignmanager/:id',
        name: 'AdDetails',
        component: () => import( /* webpackChunkName: "adcampaignmanager-id" */ '../views/AdCampaignManager/AdvertDetails.vue'),
        meta: {
            title: 'Ad Manager',
            pageName: 'Ad Manager / Ad Details',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
        redirect: {
            name: 'AdCampaignManagerDisplay',
        },
        children: [
            {
                path: '/adcampaignmanager/:id/stats',
                name: 'AdCampaignManagerDisplay',
                component: () => import( /* webpackChunkName: "adcampaignmanager-id-stats" */ '../components/AdCampaignManager/Stats/AdStats.vue'),
                meta: {
                    title: 'Ad Manager',
                    pageName: 'Ad Manager / Ad Details',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                }
            },
            {
                path: '/adcampaignmanager/:id/bannercreator',
                name: 'BannerCreator',
                component: () => import( /* webpackChunkName: "adcampaignmanager-bannercreator" */ '../components/AdCampaignManager/BannerCreation/BannerCreator.vue'),
                props: true,
                meta: {
                    title: 'Banner Creator',
                    pageName: 'Banner Creator',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
            {
                path: '/adcampaignmanager/:id/assets',
                name: 'Assets',
                component: () => import( /* webpackChunkName: "adcampaignmanager-assets" */ '../components/AdCampaignManager/BannerCreation/Assets.vue'),
                props: true,
                meta: {
                    title: 'Assets',
                    pageName: 'Assets',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
            {
                path: '/adcampaignmanager/:id/staticadcreator',
                name: 'StaticAdCreator',
                component: () => import( /* webpackChunkName: "adcampaignmanager-staticadcreator" */ '../components/AdCampaignManager/BannerCreation/StaticAdCreator.vue'),
                props: true,
                meta: {
                    title: 'Static Ad Creator',
                    pageName: 'Static Ad Creator',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
            {
                path: '/adcampaignmanager/:id/textbuttoncreator',
                name: 'TextButtonCreator',
                component: () => import( /* webpackChunkName: "adcampaignmanager-textbuttoncreator" */ '../components/AdCampaignManager/BannerCreation/TextButtonCreator.vue'),
                props: true,
                meta: {
                    title: 'Text Button Creator',
                    pageName: 'Text Button Creator',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
        ]
    },
    {
        path: '/adcampaignstatus/:id',
        name: 'AdCampaignStatus',
        component: () => import( /* webpackChunkName: "adcampaignmanager-clientview" */ '../views/AdCampaignManager/ClientStatsView.vue'),
        props: true,
        meta: {
            title: 'Dashboard - CCAdmin',
            pageName: 'Dashboard',
            layout: 'blank',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },

    {
        path: '/admanager',
        name: 'AdManager',
        component: () => import('../views/AdManager/Home.vue'),
        meta: {
            title: 'Ad Manager',
            pageName: 'Ad Manager',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/admanager/:id',
        name: 'AdManagerDisplay',
        component: () => import( /* webpackChunkName: "admanager-id" */ '../views/AdManager/Home.vue'),
        meta: {
            title: 'Ad Manager',
            pageName: 'Ad Manager / Campaign',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/adstatus/:id',
        name: 'AdStatus',
        component: () => import( /* webpackChunkName: "admanager-clientview" */ '../views/AdManager/ClientStatsView.vue'),
        props: true,
        meta: {
            title: 'Dashboard - CCAdmin',
            pageName: 'Dashboard',
            layout: 'blank',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },


    {
        path: '/indexcomposition',
        name: 'IndexcompositionHome',
        component: () => import( /* webpackChunkName: "indexcomposition-home" */ '../views/IndexComposition/Home.vue'),
        meta: {
            title: 'Indexes',
            pageName: 'Indexes',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['indexcomposition_viewer', 'indexcomposition_admin'],
        },
    },
    {
        path: '/indexcomposition/vwap/blobs/:indexCode',
        name: 'IndexcompositionVwapBlobs',
        component: () => import( /* webpackChunkName: "indexcomposition-vwap-blobs" */ '../views/IndexComposition/Vwap/Blobs.vue'),
        meta: {
            title: 'Blobs',
            pageName: 'Blobs',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['indexcomposition_viewer', 'indexcomposition_admin'],
        },
    },
    {
        path: '/indexcomposition/vwap/blob/publish/:indexCode',
        name: 'IndexcompositionVwapBlobPublish',
        component: () => import( /* webpackChunkName: "indexcomposition-vwap-blob-publish" */ '../views/IndexComposition/Vwap/BlobPublish.vue'),
        meta: {
            title: 'Blob Publishing',
            pageName: 'Blob Publishing',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['indexcomposition_editor', 'indexcomposition_admin'],
        },
    },
    {
        path: '/indexcomposition/vwap/instruments-all/:indexCode',
        name: 'IndexcompositionVwapInstrumentsAll',
        component: () => import( /* webpackChunkName: "indexcomposition-vwap-instruments-all" */ '../views/IndexComposition/Vwap/InstrumentsAll.vue'),
        props: true,
        meta: {
            title: 'All Available Instruments',
            pageName: 'All Available Instruments',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['indexcomposition_viewer', 'indexcomposition_admin'],
        },
    },
    {
        path: '/indexcomposition/vwap/instruments-included/:indexCode',
        name: 'IndexcompositionVwapInstrumentsIncluded',
        component: () => import( /* webpackChunkName: "indexcomposition-vwap-instruments-included" */ '../views/IndexComposition/Vwap/InstrumentsIncluded.vue'),
        props: true,
        meta: {
            title: 'Instruments Included In Index',
            pageName: 'Instruments Included In Index',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['indexcomposition_viewer', 'indexcomposition_admin'],
        },
    },
    {
        path: '/indexcomposition/vwap/instruments-not-included/:indexCode',
        name: 'IndexcompositionVwapInstrumentsNotIncluded',
        component: () => import( /* webpackChunkName: "indexcomposition-vwap-instruments-not-included" */ '../views/IndexComposition/Vwap/InstrumentsNotIncluded.vue'),
        props: true,
        meta: {
            title: 'Instruments Not Included',
            pageName: 'Instruments Not Included',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['indexcomposition_viewer', 'indexcomposition_admin'],
        },
    },
    {
        path: '/indexcomposition/vwap/log/:indexCode',
        name: 'IndexcompositionVwapLog',
        component: () => import( /* webpackChunkName: "indexcomposition-vwap-log" */ '../views/IndexComposition/Vwap/Log.vue'),
        meta: {
            title: 'Log',
            pageName: 'Log',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['indexcomposition_viewer', 'indexcomposition_admin'],
        },
    },
    {
        path: '/usermanager',
        redirect: {
            name: 'New Users',
        },
        name: 'UserManager',
        component: () => import('../views/UserManagement/Home.vue'),
        meta: {
            title: 'User Manager',
            pageName: 'User Manager',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
        children: [{
                path: '/usermanager',
                name: 'New Users',
                component: () => import( /* webpackChunkName: "userManager-index" */ '../components/UserManagement/Index.vue'),
                meta: {
                    title: 'User Manager',
                    pageName: 'User Manager',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
            {
                path: '/usermanager/user/:id',
                redirect: {
                    name: 'General',
                },
                name: 'UserDisplay',
                component: () => import( /* webpackChunkName: "userManager-userDisplay" */ '../components/UserManagement/UserDisplay.vue'),
                children: [{
                        path: 'general',
                        name: 'General',
                        component: () => import( /* webpackChunkName: "userManager-generalTab" */ '../components/UserManagement/General/General.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / General',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                    {
                        path: 'subscription',
                        name: 'Subscription',
                        component: () => import( /* webpackChunkName: "userManager-subscriptionTab" */ '../components/UserManagement/Subscription/Subscription.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Subscription',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                    {
                        path: 'keys',
                        name: 'Keys',
                        component: () => import( /* webpackChunkName: "userManager-keysTab" */ '../components/UserManagement/Keys/Keys.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Keys',
                            layout: 'no-sidebar',
                        },
                    },
                    {
                        path: 'calls',
                        name: 'Calls',
                        component: () => import( /* webpackChunkName: "userManager-callsTab" */ '../components/UserManagement/Calls/CallManagement.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Calls',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                    {
                        path: 'roles',
                        name: 'Roles',
                        component: () => import( /* webpackChunkName: "userManager-rolesTab" */ '../components/UserManagement/Roles/Roles.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Roles',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                    {
                        path: 'billing',
                        name: 'Billing',
                        component: () => import( /* webpackChunkName: "userManager-billingTab" */ '../components/UserManagement/Billing/BillingHistory.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Billing',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                    {
                        path: 'portfolios',
                        name: 'Portfolios',
                        component: () => import( /* webpackChunkName: "userManager-portfoliosTab" */ '../components/UserManagement/Portfolios/Portfolios.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Portfolios',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                    {
                        path: 'notifications',
                        name: 'Notifications',
                        component: () => import( /* webpackChunkName: "userManager-notificationsTab" */ '../components/UserManagement/Notifications/EmailNotifications.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Notifications',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                    {
                        path: 'security',
                        name: 'Security',
                        component: () => import( /* webpackChunkName: "userManager-secuirtyTab" */ '../components/UserManagement/Security/Security.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Security',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                    {
                        path: 'source',
                        name: 'Source',
                        component: () => import( /* webpackChunkName: "userManager-sourceTab" */ '../components/UserManagement/Source/Source.vue'),
                        props: true,
                        meta: {
                            title: 'User Manager',
                            pageName: 'User Manager / User / Source',
                            layout: 'no-sidebar',
                            authRoles: ['customer_support', 'customer_support_manager'],
                        },
                    },
                ],
            },
        ],
    },

    {
        path: '/instrumentmap',
        redirect: { name: 'InstrumentMapSpotUnmapped' },
    },

    {
        path: '/instrumentmap/futures',
        redirect: { name: 'InstrumentMapFuturesUnmapped' },
    },
    {
        path: '/instrumentmap/futures/change-request',
        name: 'InstrumentMapFuturesChangeRequest',
        component: () => import( /* webpackChunkName: "instrumentmap-futures-change-request" */ '../views/InstrumentMap/Futures/ChangeRequest.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/futures/erase',
        name: 'InstrumentMapFuturesErase',
        component: () => import( /* webpackChunkName: "instrumentmap-futures-erase" */ '../views/InstrumentMap/Futures/Erase.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/futures/expired',
        name: 'InstrumentMapFuturesExpired',
        component: () => import( /* webpackChunkName: "instrumentmap-futures-expired" */ '../views/InstrumentMap/Futures/Expired.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/futures/ignored',
        name: 'InstrumentMapFuturesIgnored',
        component: () => import( /* webpackChunkName: "instrumentmap-futures-ignored" */ '../views/InstrumentMap/Futures/Ignored.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/futures/mapped',
        name: 'InstrumentMapFuturesMapped',
        component: () => import( /* webpackChunkName: "instrumentmap-futures-mapped" */ '../views/InstrumentMap/Futures/Mapped.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/futures/retired',
        name: 'InstrumentMapFuturesRetired',
        component: () => import( /* webpackChunkName: "instrumentmap-futures-retired" */ '../views/InstrumentMap/Futures/Retired.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/futures/unmapped',
        name: 'InstrumentMapFuturesUnmapped',
        component: () => import( /* webpackChunkName: "instrumentmap-futures-unmapped" */ '../views/InstrumentMap/Futures/Unmapped.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/futures/log',
        name: 'InstrumentMapFuturesLog',
        component: () => import( /* webpackChunkName: "instrumentmap-futures-log" */ '../views/InstrumentMap/Futures/Log.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/futures/activity',
        name: 'InstrumentMapUserFuturesActivity',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-log" */ '../views/InstrumentMap/Activity/Activity.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
            instrumentType: 'futures',
        },
    },

    {
        path: '/instrumentmap/findices',
        redirect: { name: 'InstrumentMapFindicesUnmapped' },
    },
    {
        path: '/instrumentmap/findices/change-request',
        name: 'InstrumentMapFindicesChangeRequest',
        component: () => import( /* webpackChunkName: "instrumentmap-findices-change-request" */ '../views/InstrumentMap/Findices/ChangeRequest.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/findices/erase',
        name: 'InstrumentMapFindicesErase',
        component: () => import( /* webpackChunkName: "instrumentmap-findices-erase" */ '../views/InstrumentMap/Findices/Erase.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/findices/ignored',
        name: 'InstrumentMapFindicesIgnored',
        component: () => import( /* webpackChunkName: "instrumentmap-findices-ignored" */ '../views/InstrumentMap/Findices/Ignored.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/findices/mapped',
        name: 'InstrumentMapFindicesMapped',
        component: () => import( /* webpackChunkName: "instrumentmap-findices-mapped" */ '../views/InstrumentMap/Findices/Mapped.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/findices/unmapped',
        name: 'InstrumentMapFindicesUnmapped',
        component: () => import( /* webpackChunkName: "instrumentmap-findices-unmapped" */ '../views/InstrumentMap/Findices/Unmapped.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/findices/retired',
        name: 'InstrumentMapFindicesRetired',
        component: () => import( /* webpackChunkName: "instrumentmap-findices-retired" */ '../views/InstrumentMap/Findices/Retired.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/findices/log',
        name: 'InstrumentMapFindicesLog',
        component: () => import( /* webpackChunkName: "instrumentmap-findices-log" */ '../views/InstrumentMap/Findices/Log.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/findices/activity',
        name: 'InstrumentMapUserFindicesActivity',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-log" */ '../views/InstrumentMap/Activity/Activity.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
            instrumentType: 'findices',
        },
    },

    {
        path: '/instrumentmap/onchainamm',
        redirect: { name: 'InstrumentMapOnchainammUnmapped' },
    },
    {
        path: '/instrumentmap/onchainamm/change-request',
        name: 'InstrumentMapOnchainammChangeRequest',
        component: () => import( /* webpackChunkName: "instrumentmap-onchainamm-change-request" */ '../views/InstrumentMap/Onchainamm/ChangeRequest.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/onchainamm/erase',
        name: 'InstrumentMapOnchainammErase',
        component: () => import( /* webpackChunkName: "instrumentmap-onchainamm-erase" */ '../views/InstrumentMap/Onchainamm/Erase.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/onchainamm/ignored',
        name: 'InstrumentMapOnchainammIgnored',
        component: () => import( /* webpackChunkName: "instrumentmap-onchainamm-ignored" */ '../views/InstrumentMap/Onchainamm/Ignored.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/onchainamm/mapped',
        name: 'InstrumentMapOnchainammMapped',
        component: () => import( /* webpackChunkName: "instrumentmap-onchainamm-mapped" */ '../views/InstrumentMap/Onchainamm/Mapped.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/onchainamm/unmapped',
        name: 'InstrumentMapOnchainammUnmapped',
        component: () => import( /* webpackChunkName: "instrumentmap-onchainamm-unmapped" */ '../views/InstrumentMap/Onchainamm/Unmapped.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/onchainamm/retired',
        name: 'InstrumentMapOnchainammRetired',
        component: () => import( /* webpackChunkName: "instrumentmap-onchainamm-retired" */ '../views/InstrumentMap/Onchainamm/Retired.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/onchainamm/log',
        name: 'InstrumentMapOnchainammLog',
        component: () => import( /* webpackChunkName: "instrumentmap-onchainamm-log" */ '../views/InstrumentMap/Onchainamm/Log.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/onchainamm/activity',
        name: 'InstrumentMapUserOnchainammActivity',
        component: () => import( /* webpackChunkName: "instrumentmap-onchainamm-log" */ '../views/InstrumentMap/Activity/Activity.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
            instrumentType: 'onchainamm',
        },
    },

    {
        path: '/instrumentmap/spot',
        redirect: { name: 'InstrumentMapSpotUnmapped' },
    },
    {
        path: '/instrumentmap/spot/change-request',
        name: 'InstrumentMapSpotChangeRequest',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-change-request" */ '../views/InstrumentMap/Spot/ChangeRequest.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/spot/erase',
        name: 'InstrumentMapSpotErase',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-erase" */ '../views/InstrumentMap/Spot/Erase.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/spot/ignored',
        name: 'InstrumentMapSpotIgnored',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-ignored" */ '../views/InstrumentMap/Spot/Ignored.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/spot/mapped',
        name: 'InstrumentMapSpotMapped',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-mapped" */ '../views/InstrumentMap/Spot/Mapped.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/spot/unmapped',
        name: 'InstrumentMapSpotUnmapped',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-unmapped" */ '../views/InstrumentMap/Spot/Unmapped.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/spot/retired',
        name: 'InstrumentMapSpotRetired',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-retired" */ '../views/InstrumentMap/Spot/Retired.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/spot/log',
        name: 'InstrumentMapSpotLog',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-log" */ '../views/InstrumentMap/Spot/Log.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
        },
    },
    {
        path: '/instrumentmap/spot/activity',
        name: 'InstrumentMapUserSpotActivity',
        component: () => import( /* webpackChunkName: "instrumentmap-spot-log" */ '../views/InstrumentMap/Activity/Activity.vue'),
        meta: {
            title: 'Instrument Map',
            pageName: 'Instrument Map',
            requiresAuth: true,
            layout: 'no-sidebar',
            authRoles: ['instrumentmap_viewer', 'instrumentmap_editor', 'instrumentmap_admin'],
            instrumentType: 'spot',
        },
    },

    {
        path: '/insights',
        name: 'Insights',
        component: () => import('../views/Insights/Home.vue'),
        meta: {
            title: 'Insights',
            pageName: 'Insights',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/insights/api/v1',
        name: 'API Statistics V1',
        component: () => import( /* webpackChunkName: "APIStats" */ '../views/Insights/ApiStatistics.vue'),
        meta: {
            title: 'API Statistics V1',
            pageName: 'Insights / API Statistics V1',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/insights/api/v2',
        name: 'API Statistics V2',
        component: () => import( /* webpackChunkName: "APIStats" */ '../views/Insights/ApiStatistics.vue'),
        meta: {
            title: 'API Statistics V2',
            pageName: 'Insights / API Statistics V2',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/insights/api/tools',
        name: 'Tools API Statistics',
        component: () => import( /* webpackChunkName: "APIStats" */ '../views/Insights/ApiStatistics.vue'),
        meta: {
            title: 'Tools API Statistics',
            pageName: 'Insights / Tools API Statistics',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/insights/clients',
        name: 'Client Statistics',
        component: () => import( /* webpackChunkName: "clientStats" */ '../views/Insights/ClientStatistics.vue'),
        meta: {
            title: 'Client Statistics',
            pageName: 'Insights / Client Statistics',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/insights/data-streamer',
        component: () => import( /* webpackChunkName: "clientStats" */ '../views/Insights/DataStreamerStatistics.vue'),
        meta: {
            title: 'Data Streamer Statistics',
            pageName: 'Insights / Data Streamer Statistics',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
        children: [
            {
                path: '/',
                name: 'DataStreamerOverview',
                component: () => import( /* webpackChunkName: "clientStats" */ '../views/Insights/DataStreamer/Overview.vue'),
                meta: {
                    title: 'Data Streamer Overview',
                    pageName: 'Insights / Data Streamer Statistics / Data Streamer Overview',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
            {
                path: '/insights/data-streamer/machine/:machineHostname',
                name: 'MachineDetails',
                component: () => import( /* webpackChunkName: "clientStats" */ '../views/Insights/DataStreamer/MachineDetails.vue'),
                props: true,
                meta: {
                    title: 'Data Streamer Server Statistics',
                    pageName: 'Insights / Data Streamer Statistics / Server',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
            {
                path: '/insights/data-streamer/users',
                name: 'Data Streamer User Statistics',
                component: () => import( /* webpackChunkName: "clientStats" */ '../views/Insights/DataStreamer/Users.vue'),
                meta: {
                    title: 'Data Streamer User Statistics',
                    pageName: 'Insights / Data Streamer Statistics / Users',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
            {
                path: '/insights/data-streamer/subscriptions',
                name: 'Data Streamer Subscriptions Statistics',
                component: () => import( /* webpackChunkName: "clientStats" */ '../views/Insights/DataStreamer/Subscriptions.vue'),
                meta: {
                    title: 'Data Streamer Subscriptions Statistics',
                    pageName: 'Insights / Data Streamer Statistics / Subscriptions',
                    layout: 'no-sidebar',
                    authRoles: ['customer_support', 'customer_support_manager'],
                },
            },
        ]
    },
    {
        path: '/data-api-helpers',
        name: 'Data API Helpers',
        component: () => import('../views/DataAPIHelpers/Home/index.vue'),
        meta: {
            title: 'CSV Downloader',
            pageName: 'Data API Helpers',
            layout: 'no-sidebar',
            authRoles: [],
        },
    },
    {
        path: '/data-api-helpers/csv-downloader',
        name: 'CSV Downloader',
        component: () => import('../views/DataAPIHelpers/Home/CsvDownloader.vue'),
        meta: {
            title: 'CSV Downloader',
            pageName: 'Data API Helpers / CSV Downloader',
            layout: 'no-sidebar',
            authRoles: [],
        },
    },
    {
        path: '/news-manager',
        name: 'NewsManager',
        redirect: { path: '/news-manager/sources' },
        meta: {
            title: 'News Manager',
            pageName: 'News Manager',
            layout: 'no-sidebar',
            authRoles: ['news_partner'],
        },
    },
    {
        path: '/news-manager/sources',
        name: 'NewsManager / Sources',
        component: () => import('../views/NewsManager/Sources.vue'),
        props: true,
        meta: {
            title: 'News Manager',
            pageName: 'News Manager',
            layout: 'no-sidebar',
            authRoles: ['news_partner'],
        },
    },
    {
        path: '/news-manager/categories',
        name: 'NewsManager / Categories',
        component: () => import('../views/NewsManager/Categories.vue'),
        props: true,
        meta: {
            title: 'News Manager',
            pageName: 'News Manager',
            layout: 'no-sidebar',
            authRoles: ['news_partner'],
        },
    },
    {
        path: '/news-manager/articles',
        name: 'NewsManager / Articles',
        component: () => import('../views/NewsManager/Articles.vue'),
        props: true,
        meta: {
            title: 'News Manager',
            pageName: 'News Manager',
            layout: 'no-sidebar',
            authRoles: ['news_partner'],
        },
    },
    {
        path: '/exchange-discovery-service',
        name: 'EDS',
        component: () => import('../views/ExchangeDiscoveryService/Home.vue'),
        meta: {
            title: 'Exchange Discovery Service',
            pageName: 'Exchange Discovery Service',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/qa-data',
        name: 'qadata',
        component: () => import('../views/QAData/Home.vue'),
        redirect: { name: 'qadata.volume-candles' },
        meta: {
            title: 'QA Data',
            pageName: 'QA Data',
            layout: 'no-sidebar',
            authRoles: ['customer_support', 'customer_support_manager'],
        },
    },
    {
        path: '/qa-data/blob-migration',
        name: 'qadata.blob-migration',
        component: () => import('../views/QAData/BlobMigration.vue'),
        meta: {
            title: 'Blob Migration',
            pageName: 'Blob Migration',
            layout: 'no-sidebar',
            transition: 'slide-left',
            requiresAuth: true,
            authRoles: ['customer_support', 'customer_support_manager'],
            parentHeader: true,
            showInParentNavigation: true,
        },
    },
    {
        path: '/qa-data/polling-vs-streaming',
        name: 'qadata.polling-vs-streaming',
        component: () => import('../views/QAData/PollingVsStreaming.vue'),
        meta: {
            title: 'Polling vs Streaming',
            pageName: 'Polling vs Streaming',
            layout: 'no-sidebar',
            transition: 'slide-left',
            requiresAuth: true,
            authRoles: ['customer_support', 'customer_support_manager'],
            parentHeader: true,
            showInParentNavigation: true,
        },
    },
    {
        path: '/qa-data/volume-candles',
        name: 'qadata.volume-candles',
        component: () => import('../views/QAData/VolumeCandles.vue'),
        meta: {
            title: 'Volume Candles',
            pageName: 'Volume Candles',
            layout: 'no-sidebar',
            transition: 'slide-left',
            requiresAuth: true,
            authRoles: ['customer_support', 'customer_support_manager'],
            parentHeader: true,
            showInParentNavigation: true,
        },
    },
    {
        path: '/asset/contact-manager',
        name: 'AssetContactManager',
        component: () => import('../views/ContactManager/index.vue'),
        meta: {
            title: 'Asset Contact Manager',
            pageName: 'Asset Contact Manager',
            layout: 'no-sidebar',
            authRoles: [],
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name === 'AdStatus' || to.name === 'AdCampaignStatus' || to.name === 'Login') {
        next();
        return;
    }
    store
        .dispatch(`auth/${FETCH_AUTH_USER}`, null, {
            root: true,
        })
        .then(() => {
            if (to.params.symbol) {
                document.title = `${to.params.symbol} ${to.meta.title}`;
            } else if (to.params.EXCHANGE_INTERNAL_NAME) {
                document.title = `${to.params.EXCHANGE_INTERNAL_NAME.charAt(0).toUpperCase() + to.params.EXCHANGE_INTERNAL_NAME.slice(1)}`;
            } else {
                document.title = to.meta.title;
            }

            const user = store.getters['auth/user'];
            if (user !== null && to.name !== 'Home') {
                const userRoles = user.general.roles;
                let hasAccess = 0;

                if (to.matched.some((item) => item.meta.requiresAuth)) {
                    if (to.matched.some((item) => item.meta.authRoles)) {
                        hasAccess = userRoles.filter((value) => to.meta.authRoles.includes(value)).length;
                    }
                    if (hasAccess > 0) {
                        next();
                    } else {
                        next({ name: 'Home' });
                    }
                } else {
                    next();
                }
            } else {
                next();
            }
        })
        .catch(() => {
            next({ name: 'Login' });
        });
});

export default router;
