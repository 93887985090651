import Vue from 'vue';
import Vuex from 'vuex';
import { CoinsService } from "@/common/api.service";
import {
    FETCH_COINS_LOADING,
    FETCH_COINS_SUCCESS,
    FETCH_COINS_ERROR,
    RESET_COINS
} from './mutations.type'

import {
    FETCH_COINS,
    PURGE_COINS
} from './actions.type'

Vue.use(Vuex);

const initialState = {
    coins: {},
    loading: false,
    error: null
};

const state = {
    ...initialState
};

const getters = {
    coins(state) {
        return state.coins;
    },
    coinsAsArray(state) {
        return Object.values(state.coins)
    },
    isLoading(state) {
        return state.loading
    },
    error(state) {
        return state.error
    }
};

const mutations = {
    [RESET_COINS](state) {
        delete state.coins;
        state.coins = {};
    },
    [FETCH_COINS_LOADING](state) {
        Vue.set(state, 'loading', true);
    },
    [FETCH_COINS_SUCCESS](state, coins) {
        Vue.set(state, 'coins', coins);
        Vue.set(state, 'loading', false);
    },
    [FETCH_COINS_ERROR](state, error) {
        Vue.set(state, 'coins', error);
        Vue.set(state, 'loading', false);
    }
}

const actions = {
    [PURGE_COINS]({ commit }) {
        commit(RESET_COINS);
    },
    [FETCH_COINS]({ commit }) {
        commit(FETCH_COINS_LOADING);
        return CoinsService.getAll().then(({ data }) => {
            commit(FETCH_COINS_SUCCESS, data.Data)
        }).catch(error => {
            console.log(error);
            throw new Error(error);
        });
    }
};

const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default store;