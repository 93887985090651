import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const initialValues = {
  findicesMarketMessage: {

  },
  futuresMarketMessage: {

  },
  spotMarketMessage: {
    bitfinex: 'Bitfinex: when you see UST, map it as USDT',
    mtgox: 'MtGox does not exist anymore'
  }
}

const state = {
  ...JSON.parse(JSON.stringify(initialValues))
};

const getters = {
  findicesMarketMessage(state) {
    return state.findicesMarketMessage;
  },
  futuresMarketMessage(state) {
    return state.futuresMarketMessage;
  },
  spotMarketMessage(state) {
    return state.spotMarketMessage;
  }
};

const store = {
  namespaced: true,
  state,
  getters,
  actions: {},
  mutations: {},
};

export default store;
