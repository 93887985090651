export const FETCH_BLOCKEXPLORERS_LOADING = 'fetchBlockExplorersLoading';
export const FETCH_BLOCKEXPLORERS_SUCCESS = 'fetchBlockExplorersSuccess';
export const FETCH_BLOCKEXPLORERS_ERROR = 'fetchBlockExplorersError';
export const PURGE_BLOCKEXPLORERS = 'purgeBlockexplorers';

export const PURGE_BLOCKEXPLORER = 'purgeBlockExplorer';
export const PURGE_NEW_SOURCE = 'purgeNewSource';

export const SET_BLOCKEXPLORER_COIN = 'setBlockExplorerCoin';

export const FETCH_SOURCE_DATA_LOADING = 'fetchSourceDataLoading';
export const FETCH_SOURCE_DATA_SUCCESS = 'fetchSourceDataSuccess';
export const FETCH_SOURCE_DATA_ERROR = 'fetchSourceDataError';
export const APPEND_NEW_SOURCE = 'appendNewSource';
export const SET_NEW_SOURCE_URL = 'setNewSourceURL';
export const SET_NEW_SOURCE_PROP = 'setNewSourceProp';
export const SET_NEW_SOURCE_PROP_INDIVIDUAL = 'setNewSourcePropIndividual';
export const APPEND_NEW_PROP = 'appendNewProp';
export const TOGGLE_REMOVE_SOURCE = 'toggleRemoveSource';
export const HARD_REMOVE_SOURCE = 'hardRemoveSource';
export const HARD_REMOVE_PROP = 'hardRemoveProp';

export const TOGGLE_REMOVE_PROP = 'toggleRemoveProp';

export const DRY_RUN_INITIAL = 'BEDruRunInitial';
export const DRY_RUN_SOURCE_SUCCESS = 'BEDryRunSourceSuccess';
export const DRY_RUN_SOURCE_ERROR = 'BEDryRunSourceError';
export const DRY_RUN_SOURCE_LOADING = 'BEDryRunSourceLoading';

export const PROCESS_SINGLE_SOURCE_LOADING = 'processSingleSourceLoading';
export const PROCESS_SINGLE_SOURCE_SUCCESS = 'processSingleSourceSuccess';
export const PROCESS_SINGLE_SOURCE_ERROR = 'processSingleSourceError';

export const SET_COIN_SUCCESS = 'setCoinSuccess';
export const SET_COIN_LOADING = 'setCoinLoading';
export const SET_COIN_ERROR = 'setCoinError';

export const FETCH_SOURCES_LOADING = 'fetchSourcesLoading';
export const FETCH_SOURCES_SUCCESS = 'fetchSourcesSuccess';
export const FETCH_SOURCES_ERROR = 'fetchSourcesError';

export const UPDATE_BLOCKEXPLORER_LOADING = 'updateBlockExplorerLoading';
export const UPDATE_BLOCKEXPLORER_SUCCESS = 'updateBlockExplorerSuccess';
export const UPDATE_BLOCKEXPLORER_ERROR = 'updateBlockExplorerError';

export const APPEND_SOURCE_STATIC = 'appendSourceStatic';
export const TOGGLE_SOURCE_EXPAND = 'toggleSourceExpand';
export const PUBLISH_ACTION_NEWSOURCE_SUCCESS = 'publishActionNewSourceSuccess';
export const PUBLISH_ACTION_NEWSOURCE_ERROR = 'publishActionNewSourceError';

export const PUBLISH_ACTION_DELETESOURCE_SUCCESS = 'publishActionDeletedSourceSuccess';
export const PUBLISH_ACTION_NEWPROP_SUCCESS = 'publishActionNewPropSuccess';
export const PUBLISH_ACTION_DELETEPROP_SUCCESS = 'publishActionDeletePropSuccess';

export const FETCH_LOG_LOADING = 'fetchLogLoading';
export const FETCH_LOG_SUCCESS = 'fetchLogSuccess';
export const FETCH_LOG_ERROR = 'fetchLogError';
export const TOGGLE_LOG_EXPAND = 'toggleLogExpand';

export const NEW_SOURCE_OPTIONS_TOGGLE = 'newSourceOptionsExpand';
export const ADD_SOURCE_OPTION_ITEM = 'addSourceOptionsItem';
export const DELETE_SOURCE_OPTION_ITEM = 'deleteSourceOptionsItem';
export const SET_NEW_REQUEST_TYPE = 'setNewRequestType';

export const FETCH_LOG_GENERAL_LOADING = 'fetchLogGeneralLoading';
export const FETCH_LOG_GENERAL_SUCCESS = 'fetchLogGeneralSuccess';
export const FETCH_LOG_GENERAL_ERROR = 'fetchLogGeneralError';

export const SOURCE_DIRTY = 'sourceDirty';
export const SOURCE_PRISTINE = 'sourcePristine';

export const PROP_DIRTY = 'propDirty';
export const PROP_PRISTINE = 'propPristine';

export const SET_OPTIONS_LOADING = 'setOptionsLoading';
export const SET_OPTIONS_SUCCESS = 'setOptionsSuccess';
export const SET_OPTIONS_ERROR = 'setOptionsError';

export const SAVE_MAPPING_LOADING = 'saveMappingLoading';
export const SAVE_MAPPING_SUCCESS = 'saveMappingSuccess';
export const SAVE_MAPPING_ERROR = 'saveMappingError';

export const SAVE_SOURCE_LOADING = 'saveSourceLoading';
export const SAVE_SOURCE_SUCCESS = 'saveSourceSuccess';
export const SAVE_SOURCE_ERROR = 'saveSourceError';

export const SET_VUEX_PROP = 'setVuexProp';
export const ADD_NEW_SOURCE_SET_ID = 'addNewSourceSetId';
export const ADD_NEW_SOURCE_LOADING = 'addNewSourceLoading';
export const ADD_NEW_SOURCE_ERROR = 'addNewSourceError';
export const ADD_NEW_SOURCE_SUCCESS = 'addNewSourceSuccess';

export const FETCH_ASSET_LOADING = 'fetchAssetLoading';
export const FETCH_ASSET_SUCCESS = 'fetchAssetSuccess';
export const FETCH_ASSET_ERROR = 'fetchAssetError';
export const PURGE_COIN = 'purgeCoin';

export const PROCESS_DATA_SINGLE_SOURCE_LOADING = 'processDataSingleSourceLoading';
export const PROCESS_DATA_SINGLE_SOURCE_SUCCESS = 'processDataSingleSourceSuccess';
export const PROCESS_DATA_SINGLE_SOURCE_ERROR = 'processDataSingleSourceError';
export const SET_SOURCES_ORIGINAL = 'setSourcesOriginal';

export const FETCH_TOP_MARKETCAP_LOADING = 'fetchTopMarketCapLoading';
export const FETCH_TOP_MARKETCAP_SUCCESS = 'fetchTopMarketCapSuccess';
export const FETCH_TOP_MARKETCAP_ERROR = 'fetchTopMarketCapError';

export const FETCH_LOG_ACTIVITY_LOADING = 'fetchLogActivityLoading';
export const FETCH_LOG_ACTIVITY_SUCCESS = 'fetchLogActivitySuccess';
export const FETCH_LOG_ACTIVITY_ERROR = 'fetchLogActivityError';
