import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const initialValues = {
    exchangeMessage: {
        mtgox: 'MtGox does not exist anymore',
    },
}

const state = {
    ...JSON.parse(JSON.stringify(initialValues))
};

const getters = {
    exchangeMessage(state) {
        return state.exchangeMessage;
    },
};

const store = {
    namespaced: true,
    state,
    getters,
    actions: {},
    mutations: {},
};

export default store;
