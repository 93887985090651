<template>
    <v-card v-if="!loading" tile class="mx-auto" elevation="10" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
        <v-card dark flat tile class="text-center coloured-card py-5" color="#0A1623">
            <v-fade-transition>
                <h1 class="body-1">Service could not be completed</h1>
            </v-fade-transition>
        </v-card>
        <v-container class="text-center">
            <v-card-text>
                <p>Access to the tools dashboard has been denied.</p>
                <p>
                    <code>{{ error || 'Unknown error' }}</code>
                    <v-btn v-if="error" x-small icon @click="copyError">
                        <v-icon x-small>far fa-copy</v-icon>
                    </v-btn>
                </p>
            </v-card-text>
            <v-btn rounded large color="success" dark class="mb-3" href="https://www.cryptocompare.com/">Log In</v-btn>
        </v-container>
    </v-card>
    <LoadingSpinner v-else />
</template>

<script>
import { FETCH_AUTH_USER } from '@/store/modules/auth/actions.type';
import LoadingSpinner from '@/components/Common/LoadingSpinner.vue';
import store from '../store';

export default {
    name: 'Login',
    components: {
        LoadingSpinner,
    },
    data: () => ({
        loading: true,
        error: null,
    }),
    methods: {
        copyError() {
            const currentUrl = window.location.href;
            navigator.clipboard.writeText(`${this.error}, Current URL: ${currentUrl}`);
        },
    },
    created() {
        store
            .dispatch(`auth/${FETCH_AUTH_USER}`, null, {
                root: true,
            })
            .then(() => {
                this.$router.push({ path: '/' });
            })
            .catch((err) => {
                this.loading = false;
                this.error = err.response.data.Message;
            });
    },
};
</script>

