<template>
    <v-app id="cc-tools">
        <DrawerLeft />
        <Header />
        <v-main :style="`padding-left: ${drawerLeftIsVisible ? '56' : '0'}px !important`">
            <v-container>
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Header from '../components/Header/index';
import DrawerLeft from '../components/DrawerLeft';
import { mapGetters } from 'vuex';

export default {
    components: {
        Header,
        DrawerLeft,
    },
    computed: {
        ...mapGetters('settings', ['drawerLeft', 'drawerLeftIsVisible']),
    },
};
</script>