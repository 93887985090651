import axios from 'axios';

const authApiBasePath = process.env.VUE_APP_AUTH_API_URL;
const authApiAxios = axios.create({
    baseURL: authApiBasePath,
    timeout: 60000,
    withCredentials: true,
});

const communityApiBasePath = process.env.VUE_APP_COMMUNITY_API_URL;
const communityApiAxios = axios.create({
    baseURL: communityApiBasePath,
    timeout: 60000,
    withCredentials: true,
});

const dataApiBasePath = process.env.VUE_APP_DATA_API_URL;
const dataApiKey = process.env.VUE_APP_DATA_API_KEY;

let axiosDataApiObject = {
    baseURL: dataApiBasePath,
    timeout: 5000,
    withCredentials: true,
};
// we're doing this because we can't run all the metadata and integrations locally
// in production your cookie is being used to access metadata, but locally your auth cookie won't match with the prod cookie.
// to allow access to all metadata we need to send an ApiKey.
if (dataApiKey) {
    axiosDataApiObject.headers = {
        authorization: `ApiKey ${dataApiKey}`,
    };
}
const dataApiAxios = axios.create(axiosDataApiObject);

const minApiBasePath = process.env.VUE_APP_MIN_API_URL;
const minApiAxios = axios.create({
    baseURL: minApiBasePath,
    timeout: 5000,
    withCredentials: true,
});

const toolsApiBasePath = process.env.VUE_APP_TOOLS_API_URL;
const toolsApiAxios = axios.create({
    baseURL: toolsApiBasePath,
    timeout: 60000,
    withCredentials: true,
});

const dataStreamerApiBasePath = process.env.VUE_APP_DATA_STREAMER_API_URL;
const dataStreamerApiAxios = axios.create({
    baseURL: dataStreamerApiBasePath,
    timeout: 60000,
    withCredentials: true,
});

export const CoinsService = {
    getAll() {
        return minApiAxios.get('/data/all/coinlist?summary=true');
    },
    async getHisto(period, fromSymbol, toSymbol, limit) {
        try {
            const res = await minApiAxios.get(`/data/v2/histo${period}`, {
                params: {
                    fsym: fromSymbol,
                    tsym: toSymbol,
                    limit: limit,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    getHistoHour(fromSymbol, toSymbol, howManyHours = 168) {
        // 1 week = 168 hours
        return minApiAxios.get(`/data/v2/histohour?fsym=${fromSymbol}&tsym=${toSymbol}&limit=${howManyHours}`);
    },
    getHistoMinute(fromSymbol, toSymbol, howManyMinutes = 60) {
        // 1 hour = 60 minutes
        return minApiAxios.get(`/data/v2/histominute?fsym=${fromSymbol}&tsym=${toSymbol}&limit=${howManyMinutes}`);
    },
    getPriceMulti(fromSymbols = [], toSymbols = ['USD']) {
        return minApiAxios.get('/data/pricemulti', {
            params: {
                fsyms: fromSymbols.join(','),
                tsyms: toSymbols.join(','),
            },
        });
    },
    getPriceMultifull(fromSymbols = [], toSymbols = ['USD']) {
        return minApiAxios.get('/data/pricemultifull', {
            params: {
                fsyms: fromSymbols.join(','),
                tsyms: toSymbols.join(','),
            },
        });
    },
};

export const InstrumentDataService = {
    async fetchAllFiatCurrencies(page = 1, allData = [], retryCount = 0) {
        try {
            const response = await dataApiAxios.get(`/asset/v1/top/list?page=1&page_size=10&sort_by=CIRCULATING_MKT_CAP_USD&sort_direction=DESC&groups=ID,BASIC,SUPPLY,PRICE,MKT_CAP,VOLUME,CHANGE,TOPLIST_RANK`);
            const newData = response.data.Data.LIST;
            allData.push(...newData);
            const totalPages = Math.ceil(response.data.Data.STATS.TOTAL_ASSETS / 100);
            if (page < totalPages) {
                return InstrumentDataService.fetchAllFiatCurrencies(page + 1, allData);
            } else {
                return allData;
            }
        } catch (error) {
            console.error(`Error fetching page ${page}: ${error}`);
            if (retryCount < 3) {
                console.log(`Retrying fetch for page ${page}`);
                return InstrumentDataService.fetchAllFiatCurrencies(page, allData, retryCount + 1);
            } else {
                console.error(`Failed to fetch page ${page} after 3 retries`);
                return allData;
            }
        }
    },
    getAssetInfo(symbol) {
        return dataApiAxios.get('/asset/v1/metadata', {
            params: {
                asset: symbol,
                asset_lookup_priority: 'SYMBOL',
                groups: 'BASIC',
            },
        });
    },
    async assetSearch(searchString, limit = 20) {
        try {
            const res = await dataApiAxios.get('/asset/v1/search', {
                params: {
                    search_string: searchString,
                    limit: limit,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async getLastestTickForInstruments(instruments, groups = ['ID', 'VALUE', 'MOVING_24_HOUR']) {
        try {
            const res = await dataApiAxios.get('/index/cc/v1/latest/tick', {
                params: {
                    market: 'cadli',
                    instruments: instruments.join(','),
                    groups: groups.join(','),
                    apply_mapping: true,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async getAssetBySmartContractAddress(chainAssetSymbol, smartContractAddress) {
        const url = `/onchain/v2/data/by/address?asset_lookup_priority=SYMBOL&chain_asset=${chainAssetSymbol}&address=${smartContractAddress}`;
        try {
            const res = await dataApiAxios.get(url);
            return { err: null, data: res.data };
        } catch (error) {
            const fullUrl = `${error.config.baseURL}${error.config.url}`;
            const errObject = {
                url: fullUrl,
                message: `${error.message} - ${fullUrl}`,
            };

            return { err: errObject, data: null };
        }
    },
    async getAssetsSummaryData(assetIds = [], lookupPriority = 'ID') {
        const url = `/asset/v1/summary/list`;
        try {
            const res = await dataApiAxios.get(url, {
                params: {
                    assets: assetIds.join(','),
                    asset_lookup_priority: lookupPriority,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            const fullUrl = `${error.config.baseURL}${error.config.url}`;
            const errObject = {
                url: fullUrl,
                message: `${error.message} - ${fullUrl}`,
            };

            return { err: errObject, data: null };
        }
    },
    async getAssetBySymbolOrId(assetSymbolOrId, lookupPriority = 'SYMBOL', groups = 'ID,BASIC') {
        try {
            const res = await dataApiAxios.get('/asset/v1/metadata', {
                params: {
                    asset: assetSymbolOrId,
                    asset_lookup_priority: lookupPriority,
                    groups: groups,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            const fullUrl = `${error.config.baseURL}${error.config.url}`;
            const errObject = {
                url: fullUrl,
                message: `${error.message} - ${fullUrl}`,
            };

            return { err: errObject, data: null };
        }
    },
    async updateInstrumentComment(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/common/upsert/instrument-comment', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async updateUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/onchainamm/update/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async referenceRateGetHisto(period, market, instrument, limit, applyMapping = false) {
        try {
            const res = await dataApiAxios.get(`/index/cc/v1/historical/${period}`, {
                params: {
                    market: market,
                    instrument: instrument,
                    limit: limit,
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    findicesGetInstrumentMetadata(market, instruments, applyMapping = false) {
        return dataApiAxios.get(`/index/v1/latest/instrument/metadata?market=${market}&instruments=${instruments.join(',')}&apply_mapping=${applyMapping}`);
    },
    async findicesGetLatestTick(market, instruments, applyMapping = false) {
        try {
            const res = await dataApiAxios.get(`/index/v1/latest/tick`, {
                params: {
                    market: market,
                    instruments: instruments.join(','),
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async futuresGetHisto(period, market, instrument, limit, applyMapping = false) {
        try {
            const res = await dataApiAxios.get(`/futures/v1/historical/${period}`, {
                params: {
                    market: market,
                    instrument: instrument,
                    limit: limit,
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    futuresGetInstrumentMetadata(market, instruments, applyMapping = false) {
        return dataApiAxios.get(`/futures/v1/latest/instrument/metadata?market=${market}&instruments=${instruments.join(',')}&apply_mapping=${applyMapping}`);
    },
    async futuresGetLatestTick(market, instruments, applyMapping = false) {
        try {
            const res = await dataApiAxios.get('/futures/v1/latest/tick', {
                params: {
                    market: market,
                    instruments: instruments.join(','),
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async findicesGetHisto(period, market, instrument, limit, applyMapping = false) {
        try {
            const res = await dataApiAxios.get(`/index/v1/historical/${period}`, {
                params: {
                    market: market,
                    instrument: instrument,
                    limit: limit,
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async onchainammGetHisto(period, market, instrument, limit, applyMapping = false) {
        try {
            const res = await dataApiAxios.get(`/onchain/v1/amm/historical/swap/${period}`, {
                params: {
                    market: market,
                    instrument: instrument,
                    limit: limit,
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    onchainammGetInstrumentMetadata(market, instruments, applyMapping = false) {
        return dataApiAxios.get(`/onchain/v1/amm/latest/instrument/metadata?market=${market}&instruments=${instruments.join(',')}&apply_mapping=${applyMapping}`);
    },
    async onchainammGetLatestTick(market, instruments, applyMapping = false) {
        try {
            const res = await dataApiAxios.get('/onchain/v1/amm/latest/swap/tick', {
                params: {
                    market: market,
                    instruments: instruments.join(','),
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async spotGetHisto(period, market, instrument, limit, applyMapping = false) {
        try {
            const res = await dataApiAxios.get(`/spot/v1/historical/${period}`, {
                params: {
                    market: market,
                    instrument: instrument,
                    limit: limit,
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async spotUpdateUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/spot/update/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    spotGetInstrumentMetadata(market, instruments, applyMapping = false) {
        return dataApiAxios.get(`/spot/v1/latest/instrument/metadata?market=${market}&instruments=${instruments.join(',')}&apply_mapping=${applyMapping}`);
    },
    async spotGetLatestTick(market, instruments, applyMapping = false) {
        try {
            const res = await dataApiAxios.get('/spot/v1/latest/tick', {
                params: {
                    market: market,
                    instruments: instruments.join(','),
                    apply_mapping: applyMapping,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async spotAddUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/spot/add/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async spotDeleteUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/spot/delete/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async getAssetsForMapping() {
        try {
            const res = await toolsApiAxios.get('/asset/v1/assets/for/mapping', {
                params: {},
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async futuresAddUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/futures/add/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async futuresDeleteUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/futures/delete/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async findicesUpdateUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/findices/update/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async findicesAddUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/findices/add/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    async findicesDeleteUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/findices/delete/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
};

export const AssetsManagementService = {
    getOptions() {
        return toolsApiAxios.get('/asset/v1/options/list');
    },
    searchAssets(searchString) {
        return toolsApiAxios.get('/asset/v1/search', {
            params: { search_string: searchString, limit: 50 },
        });
    },
    getAssetsMultiple(queryParams) {
        return toolsApiAxios.get('/asset/v1/data/by/symbols/list', {
            params: queryParams,
        });
    },
    getAssetInfo(id, groups = 'TOPLIST_RANK,PRICE,VOLUME') {
        return dataApiAxios.get('/asset/v1/metadata', {
            params: {
                asset: id,
                asset_lookup_priority: 'ID',
                groups: groups,
            },
        });
    },
    getAssetsList(queryParams) {
        return toolsApiAxios.get('/asset/v1/top/list', {
            params: queryParams,
        });
    },
    getAlternativeAssetsList(queryParams) {
        return toolsApiAxios.get('/asset/v1/alternative/list', {
            params: queryParams,
        });
    },
    getAssetIssuerTopList(queryParams) {
        return toolsApiAxios.get('/asset/v1/issuer/top/list', {
            params: queryParams,
        });
    },
    getAssetDeletedList(queryParams) {
        return toolsApiAxios.get('/asset/v1/deleted/assets', {
            params: queryParams,
        });
    },
    addAssetIssuer(data) {
        return toolsApiAxios.post('/asset/v1/issuer/add', data);
    },
    updateAssetIssuer(data) {
        return toolsApiAxios.post('/asset/v1/issuer/update', data);
    },
    updateIssuerOfAsset(data) {
        return toolsApiAxios.post('/asset/v1/issuer/id/update', data);
    },
    updateParentAsset(data) {
        return toolsApiAxios.post('/asset/v1/parent/update', data);
    },
    getAssetStaticFields(data) {
        return toolsApiAxios.post('/asset/v1/field/list', data);
    },
    addAsset(data) {
        return toolsApiAxios.post('/asset/v1/add', data);
    },
    uploadFileForAsset(data) {
        return toolsApiAxios.post('asset/v1/file/upload', data);
    },
    getAssetPrice(fsym) {
        return minApiAxios.get('/data/price', {
            params: {
                fsym: fsym,
                tsym: 'USD',
            },
        });
    },
    getAssetAllPriceData(fsyms) {
        return minApiAxios.get('/data/pricemultifull', {
            params: {
                fsyms: fsyms,
                tsyms: 'USD',
            },
        });
    },
    getAsset(assetSymbol) {
        return toolsApiAxios.get('/asset/v1/list/sources', {
            params: {
                asset_symbol: assetSymbol,
            },
        });
    },
    staticFieldAdd(data) {
        return toolsApiAxios.post('/asset/v1/field/add', data);
    },
    staticFieldUpdate(data) {
        return toolsApiAxios.post('/asset/v1/field/update', data);
    },
    staticFieldRemove(data) {
        return toolsApiAxios.post('/asset/v1/field/remove', data);
    },
    getLog(assetId) {
        return toolsApiAxios.get('/asset/v1/log/list', {
            params: {
                id: assetId,
            },
        });
    },
    proxyRequest(data) {
        return toolsApiAxios.post('/asset/v1/proxy/request', data);
    },
    getSources(id) {
        return toolsApiAxios.get('/asset/v1/source/list', {
            params: {
                asset_id: id,
            },
        });
    },
    addSource(data) {
        return toolsApiAxios.post('/asset/v1/source/add', data);
    },
    removeSource(data) {
        return toolsApiAxios.post('/asset/v1/source/remove', data);
    },
    updateSourceSortOrder(data) {
        return toolsApiAxios.post('/asset/v1/source/sortorder/update', data);
    },
    addDynamicField(data) {
        return toolsApiAxios.post('/asset/v1/source/response/field/config/add', data);
    },
    updateDynamicField(data) {
        return toolsApiAxios.post('/asset/v1/source/response/field/config/update', data);
    },
    removeDynamicField(data) {
        return toolsApiAxios.post('/asset/v1/source/response/field/config/remove', data);
    },
    lookup() {
        return toolsApiAxios.get;
    },
    importGetLegacySources(symbol) {
        return toolsApiAxios.get('/blockexplorers/list/sources', {
            params: {
                asset_symbol: symbol,
            },
        });
    },
    eventsAdd(data) {
        return toolsApiAxios.post('/asset/v1/event/add', data);
    },
    eventsUpdate(data) {
        return toolsApiAxios.post('/asset/v1/event/update', data);
    },
    eventsRemove(data) {
        return toolsApiAxios.post('/asset/v1/event/remove', data);
    },
    eventsList(id) {
        return toolsApiAxios.get('/asset/v1/event/list', {
            params: {
                asset_id: id,
            },
        });
    },
    getSupplyData(id, limit, ts) {
        return toolsApiAxios.get('/asset/v1/historical/day/list', {
            params: {
                asset_id: id,
                limit: limit,
                to_ts: ts,
            },
        });
    },
    updateHistoSupplyDay(data) {
        return toolsApiAxios.post('/asset/v1/historical/day/update', data);
    },
    addHistoSupplyDay(data) {
        return toolsApiAxios.post('/asset/v1/historical/day/add', data);
    },
    removeHistoSupplyDay(data) {
        return toolsApiAxios.post('/asset/v1/historical/day/remove', data);
    },
    getUsers(params) {
        return toolsApiAxios.get('/asset/v1/assign/list', {
            params,
        });
    },
    updateAssignedTo(data) {
        return toolsApiAxios.post('/asset/v1/assign/update', data);
    },
    migrateAssetAssignments(data) {
        return toolsApiAxios.post('/asset/v1/assign/migrate', data);
    },
    getLogDayActivity(afterTs) {
        return toolsApiAxios.get('/asset/v1/log/by/day/list', {
            params: {
                after_ts: afterTs,
            },
        });
    },
    getStatsDataForAssetSocialField(data) {
        return toolsApiAxios.post('/asset/v1/field/social/stats/get', data);
    },
    restoreAsset(data) {
        return toolsApiAxios.post('/asset/v1/restore', data);
    },
    deleteAsset(data) {
        return toolsApiAxios.post('/asset/v1/delete', data);
    },
    updateAssetSymbol(data) {
        return toolsApiAxios.post('/asset/v1/symbol/update', data);
    },
    updateAssetLegacyId(data) {
        return toolsApiAxios.post('/asset/v1/legacyid/update', data);
    },
    updateAssetUri(data) {
        return toolsApiAxios.post('/asset/v1/uri/update', data);
    },
    updateComment(data) {
        return toolsApiAxios.post('/asset/v1/comment/update', data);
    },

    getAssetContacts(queryParams) {
        return toolsApiAxios.get('/asset/v1/contact/list', {
            params: queryParams,
        });
    },
    updateSymbolMappingChangesForecast(data) {
        return toolsApiAxios.post('/asset/v1/symbol/update/mapping/changes/forecast', data);
    },
    getOnChainRequestList(queryParams) {
        return toolsApiAxios.get('/asset/v1/listing/request/onchain/list', {
            params: queryParams,
        });
    },
    getOnChainRequestListAlreadyAdded(queryParams) {
        return toolsApiAxios.get('/asset/v1/listing/request/onchain/already/added/list', {
            params: queryParams,
        });
    },
    async getAssetBySmartContractAddress(queryParams) {
        try {
            const res = await dataApiAxios.get('/onchain/v2/data/by/address', {
                params: queryParams,
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    listingOnchainInstrumentList(queryParams) {
        return toolsApiAxios.get('/asset/v1/listing/request/onchain/instruments/list', {
            params: queryParams,
        });
    },
    markAdded(data) {
        return toolsApiAxios.post('/asset/v1/listing/request/onchain/mark/added', data);
    },
    markNotAdded(data) {
        return toolsApiAxios.post('/asset/v1/listing/request/onchain/mark/not/added', data);
    },
};

export const ExchangeManagementService = {
    getOptions() {
        return toolsApiAxios.get('/exchange/v1/options/list');
    },
    addExchange(data) {
        return toolsApiAxios.post('/exchange/v1/add', data);
    },
    deleteExchange(data) {
        return toolsApiAxios.post('/exchange/v1/delete', data);
    },
    restoreExchange(data) {
        return toolsApiAxios.post('/exchange/v1/restore', data);
    },
    updateComment(data) {
        return toolsApiAxios.post('/exchange/v1/comment/update', data);
    },
    updateAssignedTo(data) {
        return toolsApiAxios.post('/exchange/v1/assign/update', data);
    },
    updateUri(data) {
        return toolsApiAxios.post('/exchange/v1/uri/update', data);
    },
    assignedToList(queryParams) {
        return toolsApiAxios.get('/exchange/v1/assign/list', {
            params: queryParams,
        });
    },
    staticFieldAdd(data) {
        return toolsApiAxios.post('/exchange/v1/field/add', data);
    },
    staticFieldRemove(data) {
        return toolsApiAxios.post('/exchange/v1/field/remove', data);
    },
    staticFieldUpdate(data) {
        return toolsApiAxios.post('/exchange/v1/field/update', data);
    },
    staticFieldListExchangesById(data) {
        return toolsApiAxios.post('/exchange/v1/field/list', data);
    },
    addEvent(data) {
        return toolsApiAxios.post('/exchange/v1/event/add', data);
    },
    removeEvent(data) {
        return toolsApiAxios.post('/exchange/v1/event/remove', data);
    },
    updateEvent(data) {
        return toolsApiAxios.post('/exchange/v1/event/update', data);
    },
    eventListExchangesById(queryParams) {
        return toolsApiAxios.get('/exchange/v1/event/list', {
            params: queryParams,
        });
    },
    exchangesListByInternalName(queryParams) {
        return toolsApiAxios.get('/exchange/v1/data/by/internal_names/list', {
            params: queryParams,
        });
    },
    exchangesList(queryParams) {
        return toolsApiAxios.get('/exchange/v1/list/exchanges', {
            params: queryParams,
        });
    },
    exchangesListDeleted(queryParams) {
        return toolsApiAxios.get('/exchange/v1/deleted/exchanges', {
            params: queryParams,
        });
    },
    searchInternalExchangeName(queryParams) {
        return toolsApiAxios.get('/exchange/v1/search', {
            params: queryParams,
        });
    },
    logListByExchangeId(queryParams) {
        return toolsApiAxios.get('/exchange/v1/log/list', {
            params: queryParams,
        });
    },
    logListActivityByDay(queryParams) {
        return toolsApiAxios.get('/exchange/v1/log/by/day/list', {
            params: queryParams,
        });
    },
    uploadFile(data) {
        return toolsApiAxios.post('/exchange/v1/file/upload', data);
    },
    migrateExchangeAssignmentsByType(data) {
        return toolsApiAxios.post('/exchange/v1/assign/migrate', data);
    },
};

export const BlockExplorerService = {
    getBlockExplorers() {
        return toolsApiAxios.get('/blockexplorers/list');
    },
    getOptions() {
        return toolsApiAxios.get('/blockexplorers/options');
    },
    proxyRequest(data) {
        return toolsApiAxios.post('/blockexplorers/proxy/request', data);
    },
    getSources(assetSymbol) {
        return toolsApiAxios.get('/blockexplorers/list/sources', {
            params: {
                asset_symbol: assetSymbol,
            },
        });
    },
    getLog(symbol) {
        return toolsApiAxios.get('/blockexplorers/list/log', {
            params: {
                asset_symbol: symbol,
            },
        });
    },
    getLogs() {
        const url = `${toolsApiBasePath}/blockexplorers/logs`;
        return toolsApiAxios.get(url);
    },
    addSource(data) {
        return toolsApiAxios.post('/blockexplorers/add/source', data);
    },
    updateSource(data) {
        return toolsApiAxios.post('/blockexplorers/update/source', data);
    },
    updateMapping(data) {
        return toolsApiAxios.post('/blockexplorers/update/mapping', data);
    },
    updateMappingStatic(data) {
        const proxyUrl = `${toolsApiBasePath}/blockexplorers/update/mapping/static`;
        return toolsApiAxios.post(proxyUrl, data);
    },
    deleteSource(data) {
        return toolsApiAxios.post('/blockexplorers/remove/source', data);
    },
    newProp(data) {
        return toolsApiAxios.post('/blockexplorers/add/mapping', data);
    },
    newPropStatic(data) {
        return toolsApiAxios.post('/blockexplorers/add/mapping/static', data);
    },
    deleteProp(data) {
        return toolsApiAxios.post('/blockexplorers/remove/mapping', data);
    },
    processBlockExplorerData(symbol) {
        return toolsApiAxios.get('/blockexplorers/process/data', {
            params: {
                asset_symbol: symbol,
            },
        });
    },
    processBlockExplorerDataBySourceId(sourceId) {
        return toolsApiAxios.get('/blockexplorers/process/source/data', {
            params: {
                id: sourceId,
            },
        });
    },
    getAsset(symbol) {
        return minApiAxios.get('/data/price', {
            params: {
                fsym: symbol,
                tsym: 'USD',
            },
        });
    },
    getTopMarketCap(queryParams) {
        return toolsApiAxios.get('/blockexplorers/global/listings', {
            params: queryParams,
        });
    },
    getLogDayActivity(afterTs) {
        return toolsApiAxios.get('/blockexplorers/list/log/day/activity', {
            params: {
                after_ts: afterTs,
            },
        });
    },
};

export const AuthService = {
    getAuthUser() {
        return authApiAxios.get('/cryptopian/get');
    },
};

export const AdService = {
    getAdHistorical(adId, limit, toTs, viewKey) {
        let params = {
            id: adId,
            limit: limit,
        };

        if (toTs) {
            params.toTs = toTs;
        }

        if (viewKey) {
            params.view_key = viewKey;
        }

        return toolsApiAxios.get('/people/get/numbers/info/histoday', {
            params: params,
        });
    },
    addNewAdvert(clientName, plannedViews, selectedPlacement, selectedType, selectedCountries, redirectLinkDesktop, redirectLinkMobile, invoiceLink, advertisingAgreement) {
        let params = {};

        params.client = clientName;
        if (plannedViews) {
            params.planned_views = plannedViews;
        }
        if (redirectLinkDesktop) {
            params.click_redirect_url_desktop = redirectLinkDesktop;
        }
        if (redirectLinkMobile) {
            params.click_redirect_url_mobile = redirectLinkMobile;
        }
        if (invoiceLink) {
            params.invoice_link = invoiceLink;
        }
        if (advertisingAgreement) {
            params.advertising_agreement_link = advertisingAgreement;
        }
        params.placement = selectedPlacement;
        params.placement_type = selectedType;
        if (selectedCountries) {
            params.countries_list = selectedCountries;
        }
        return toolsApiAxios.get('/people/get/numbers/new', {
            params: params,
        });
    },
    getOptions() {
        return toolsApiAxios.get('/people/options');
    },
    updateExistingAdvert(client, redirectLinkDesktop, redirectLinkMobile, invoiceLink, advertisingAgreement, listOfCountries) {
        let params = {};

        params.id = client;
        if (redirectLinkDesktop !== undefined && redirectLinkDesktop !== null) {
            params.click_redirect_url_desktop = redirectLinkDesktop;
        }
        if (redirectLinkMobile !== undefined && redirectLinkMobile !== null) {
            params.click_redirect_url_mobile = redirectLinkMobile;
        }
        if (invoiceLink !== undefined && invoiceLink !== null) {
            params.invoice_link = invoiceLink;
        }
        if (advertisingAgreement !== undefined && advertisingAgreement !== null) {
            params.advertising_agreement_link = advertisingAgreement;
        }
        if (listOfCountries !== undefined && listOfCountries !== null) {
            params.countries_list = listOfCountries;
        }
        return toolsApiAxios.get('/people/get/numbers/update', {
            params: params,
        });
    },
};

export const AdCampaignManagerService = {
    getAllAds(params) {
        return communityApiAxios.get('/people/v1/group/numbers/list', {
            params: params
        });
    },
    getAllCampaigns(params) {
        return communityApiAxios.get('/people/v1/group/list', {
            params: params,
        });
    },
    getCampaignById(id) {
        const params = {
            id: id,
        };
        return communityApiAxios.get('/people/v1/group/info', {
            params: params,
        });
    },
    createNewCampaign(params) {
        return communityApiAxios.post('/people/v1/group/new', params);
    },
    updateCampaign(params) {
        return communityApiAxios.post('/people/v1/group/update', params);
    },
    getAdGeneralData(id) {
        const params = {
            id: id,
        };
        return communityApiAxios.get('/people/v1/group/numbers/info', {
            params: params,
        });
    },
    getAdStatsHistoricalData(adId, limit, toTs, viewKey) {
        let params = {
            id: adId,
            limit: limit,
        };

        if (toTs) {
            params.toTs = toTs;
        }

        if (viewKey) {
            params.view_key = viewKey;
        }

        return communityApiAxios.get('/people/v1/group/numbers/info/histoday', {
            params: params,
        });
    },
    createNewAd(params) {
        return communityApiAxios.post('/people/v1/group/numbers/new', params);
    },
    getAdOptions() {
        return communityApiAxios.get('/people/v1/group/numbers/options');
    },
    updateAd(params) {
        return communityApiAxios.post('/people/v1/group/numbers/update', params);
    },
    createBanner(params) {
        return communityApiAxios.post('/people/v1/group/numbers/code/new', params);
    },
    updateBanner(params) {
        return communityApiAxios.post('/people/v1/group/numbers/code/update', params);
    },
    getBannerCode(id) {
        const params = {
            ad_id: id,
        };
        return communityApiAxios.get('/people/v1/group/numbers/code/info', {
            params: params,
        });
    },
    getTextButton(id) {
        const params = {
            ad_id: id,
        };
        return communityApiAxios.get('/people/v1/group/numbers/button/info', {
            params: params,
        });
    },
    createTextButton(params) {
        return communityApiAxios.post('/people/v1/group/numbers/button/new', params);
    },
    updateTextButton(params) {
        return communityApiAxios.post('/people/v1/group/numbers/button/update', params);
    },
    getAssets(id) {
        const params = {
            ad_id: id,
        };
        return communityApiAxios.get('/people/v1/group/numbers/image/list', {
            params: params,
        });
    },
    uploadAsset(params) {
        return communityApiAxios.post('/people/v1/group/numbers/image/upload', params);
    },
    deleteAsset(params) {
        return communityApiAxios.get('/people/v1/group/numbers/image/delete', {
            params: params,
        });
    }
};

export const IndexCompositionService = {
    commonAddIndex(queryParams) {
        return toolsApiAxios.post('/indexcomposition/common/add/index', queryParams);
    },
    commonInfoMarket() {
        return toolsApiAxios.get('/indexcomposition/common/info/market');
    },
    commonListBlob(queryParams) {
        return toolsApiAxios.get('/indexcomposition/common/list/blob', { params: queryParams });
    },
    commonGetBlob(queryParams) {
        return toolsApiAxios.get('/indexcomposition/common/get/blob', { params: queryParams });
    },
    commonListIndex(queryParams) {
        return toolsApiAxios.get('/indexcomposition/common/list/index', { params: queryParams });
    },
    commonListLogFreetext(queryParams) {
        return toolsApiAxios.get('/indexcomposition/common/list/log/freetext', { params: queryParams });
    },
    commonListLogUser() {
        return toolsApiAxios.get('/indexcomposition/common/list/log/user');
    },
    commonUpdateIndex(queryParams) {
        return toolsApiAxios.post('/indexcomposition/common/update/index', queryParams);
    },
    commonUpdateIndexLock(queryParams) {
        return toolsApiAxios.post('/indexcomposition/common/update/index/lock', queryParams);
    },
    vwapExcludeInstrument(queryParams) {
        return toolsApiAxios.post('/indexcomposition/vwap/exclude/instrument', queryParams);
    },
    vwapGenerateAndAddBlob(queryParams) {
        return toolsApiAxios.post('/indexcomposition/vwap/generate-and-add/blob', queryParams);
    },
    vwapGetChangesSinceBlob(queryParams) {
        return toolsApiAxios.get('/indexcomposition/vwap/get/changes-since-blob', { params: queryParams });
    },
    vwapGetDifferencesBetweenBlobs(queryParams) {
        return toolsApiAxios.get('/indexcomposition/vwap/get/differences-between-blobs', { params: queryParams });
    },
    vwapHasIndexIncludedNonmappedInstruments(queryParams) {
        return toolsApiAxios.get('/indexcomposition/vwap/has/instrument/nonmapped', { params: queryParams });
    },
    vwapIncludeInstrument(queryParams) {
        return toolsApiAxios.post('/indexcomposition/vwap/include/instrument', queryParams);
    },
    vwapListIndexInstrument(queryParams) {
        return toolsApiAxios.get('/indexcomposition/vwap/list/index-instrument', { params: queryParams });
    },
    vwapListInstrumentFreetext(queryParams) {
        return toolsApiAxios.get('/indexcomposition/vwap/list/instrument/freetext', { params: queryParams });
    },
    vwapListInstrumentNotIncludedFreetext(queryParams) {
        return toolsApiAxios.get('/indexcomposition/vwap/list/instrument-not-included/freetext', { params: queryParams });
    },
    vwapUpdateIndexInstrument(queryParams) {
        return toolsApiAxios.post('/indexcomposition/vwap/update/index-instrument', queryParams);
    },
};

export const InstrumentMapService = {
    commonListLog(queryParams) {
        return toolsApiAxios.get('/instrumentmap/common/list/log', {
            params: queryParams,
        });
    },
    commonListLogFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/common/list/log/freetext', {
            params: queryParams,
        });
    },
    commonListUser() {
        return toolsApiAxios.get('/instrumentmap/common/list/user');
    },
    findicesAddChangeRequest(queryParams) {
        return toolsApiAxios.post('/instrumentmap/findices/add/change-request', queryParams);
    },
    findicesErase(queryParams) {
        return toolsApiAxios.post('/instrumentmap/findices/erase', queryParams);
    },
    findicesGenerateMappedInstrumentId(queryParams) {
        return toolsApiAxios.get('/instrumentmap/findices/generate-mapped-instrument-id', {
            params: queryParams,
        });
    },
    findicesInfoMarket() {
        return toolsApiAxios.get('/instrumentmap/findices/info/market');
    },
    findicesInfoOption() {
        return toolsApiAxios.get('/instrumentmap/findices/info/option');
    },
    findicesListChangeRequest(queryParams) {
        return toolsApiAxios.get('/instrumentmap/findices/list/change-request', {
            params: queryParams,
        });
    },
    findicesListChangeRequestFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/findices/list/change-request/freetext', {
            params: queryParams,
        });
    },
    findicesListMapping(queryParams) {
        return toolsApiAxios.get('/instrumentmap/findices/list/mapping', {
            params: queryParams,
        });
    },
    findicesListMappingFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/findices/list/mapping/freetext', {
            params: queryParams,
        });
    },
    findicesListUnmappedFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/findices/list/unmapped/freetext', {
            params: queryParams,
        });
    },
    findicesUpdateChangeRequestApproval(queryParams) {
        return toolsApiAxios.post('/instrumentmap/findices/update/change-request/approval', queryParams);
    },
    futuresAddChangeRequest(queryParams) {
        return toolsApiAxios.post('/instrumentmap/futures/add/change-request', queryParams);
    },
    futuresErase(queryParams) {
        return toolsApiAxios.post('/instrumentmap/futures/erase', queryParams);
    },
    async futuresGenerateMappedInstrumentId(queryParams) {
        try {
            const res = await toolsApiAxios.get(`/instrumentmap/futures/generate-mapped-instrument-id`, {
                params: queryParams,
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    futuresInfoMarket() {
        return toolsApiAxios.get('/instrumentmap/futures/info/market');
    },
    futuresInfoOption() {
        return toolsApiAxios.get('/instrumentmap/futures/info/option');
    },
    futuresListChangeRequest(queryParams) {
        return toolsApiAxios.get('/instrumentmap/futures/list/change-request', {
            params: queryParams,
        });
    },
    futuresListChangeRequestFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/futures/list/change-request/freetext', {
            params: queryParams,
        });
    },
    futuresListMapping(queryParams) {
        return toolsApiAxios.get('/instrumentmap/futures/list/mapping', {
            params: queryParams,
        });
    },
    futuresListMappingFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/futures/list/mapping/freetext', {
            params: queryParams,
        });
    },
    futuresListUnmappedFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/futures/list/unmapped/freetext', {
            params: queryParams,
        });
    },
    futuresUpdateChangeRequestApproval(queryParams) {
        return toolsApiAxios.post('/instrumentmap/futures/update/change-request/approval', queryParams);
    },
    async futuresUpdateUnmappedInstrument(data) {
        try {
            const res = await toolsApiAxios.post('/instrumentmap/futures/update/unmapped-instrument', data);
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    onchainammAddChangeRequest(queryParams) {
        return toolsApiAxios.post('/instrumentmap/onchainamm/add/change-request', queryParams);
    },
    onchainammErase(queryParams) {
        return toolsApiAxios.post('/instrumentmap/onchainamm/erase', queryParams);
    },
    onchainammGenerateMappedInstrumentId(queryParams) {
        return toolsApiAxios.get('/instrumentmap/onchainamm/generate-mapped-instrument-id', {
            params: queryParams,
        });
    },
    onchainammInfoMarket() {
        return toolsApiAxios.get('/instrumentmap/onchainamm/info/market');
    },
    onchainammInfoOption() {
        return toolsApiAxios.get('/instrumentmap/onchainamm/info/option');
    },
    onchainammListChangeRequest(queryParams) {
        return toolsApiAxios.get('/instrumentmap/onchainamm/list/change-request', {
            params: queryParams,
        });
    },
    onchainammListChangeRequestFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/onchainamm/list/change-request/freetext', {
            params: queryParams,
        });
    },
    onchainammListMapping(queryParams) {
        return toolsApiAxios.get('/instrumentmap/onchainamm/list/mapping', {
            params: queryParams,
        });
    },
    onchainammListMappingFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/onchainamm/list/mapping/freetext', {
            params: queryParams,
        });
    },
    onchainammListUnmappedFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/onchainamm/list/unmapped/freetext', {
            params: queryParams,
        });
    },
    onchainammUpdateChangeRequestApproval(queryParams) {
        return toolsApiAxios.post('/instrumentmap/onchainamm/update/change-request/approval', queryParams);
    },
    spotAddChangeRequest(queryParams) {
        return toolsApiAxios.post('/instrumentmap/spot/add/change-request', queryParams);
    },
    spotErase(queryParams) {
        return toolsApiAxios.post('/instrumentmap/spot/erase', queryParams);
    },
    async spotGenerateMappedInstrumentId(queryParams) {
        try {
            const res = await toolsApiAxios.get(`/instrumentmap/spot/generate-mapped-instrument-id`, {
                params: {
                    ...queryParams,
                },
            });
            return { err: null, data: res.data };
        } catch (error) {
            return { err: error, data: null };
        }
    },
    spotInfoMarket() {
        return toolsApiAxios.get('/instrumentmap/spot/info/market');
    },
    spotInfoOption() {
        return toolsApiAxios.get('/instrumentmap/spot/info/option');
    },
    spotListChangeRequest(queryParams) {
        return toolsApiAxios.get('/instrumentmap/spot/list/change-request', {
            params: queryParams,
        });
    },
    spotListChangeRequestFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/spot/list/change-request/freetext', {
            params: queryParams,
        });
    },
    spotListMapping(queryParams) {
        return toolsApiAxios.get('/instrumentmap/spot/list/mapping', {
            params: queryParams,
        });
    },
    spotListMappingFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/spot/list/mapping/freetext', {
            params: queryParams,
        });
    },
    spotListUnmappedFreetext(queryParams) {
        return toolsApiAxios.get('/instrumentmap/spot/list/unmapped/freetext', {
            params: queryParams,
        });
    },
    spotUpdateChangeRequestApproval(queryParams) {
        return toolsApiAxios.post('/instrumentmap/spot/update/change-request/approval', queryParams);
    },
    getLogActivityByDay(afterTs, instrumentType) {
        return toolsApiAxios.get('/instrumentmap/common/list/log/by/day', {
            params: {
                after_ts: afterTs,
                instrument_type: instrumentType,
            },
        });
    },
};

export const ExchangeDiscovery = {
    getExchangeIntegrationHosts(queryParams) {
        return toolsApiAxios.get('/exchange-discovery/get/exchange-hosts', {
            params: queryParams,
        });
    },
    removeExchangeHostFromExchangeIntegrationHostsList(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/remove/exchange-host', {
            params: queryParams,
        });
    },
    createExchangeHostMetadata(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/create/exchange-host-metadata', {
            params: queryParams,
        });
    },
    getExchangeHostMetadata(queryParams) {
        return toolsApiAxios.get('/exchange-discovery/get/exchange-host-metadata', {
            params: queryParams,
        });
    },
    updateExchangeHostMetadata(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/update/exchange-host-metadata', {
            params: queryParams,
        });
    },
    removeExchangeHostMetadata(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/remove/exchange-host-metadata', {
            params: queryParams,
        });
    },
    assignInstrumentsToExchangeHostForAssingmentMetadataType(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/add/exchange-host/instrument', {
            params: queryParams,
        });
    },
    getExchangeHostInstrumentAssingmentForAssingmentMetadataType(queryParams) {
        return toolsApiAxios.get('/exchange-discovery/get/exchange-host-assigned-instruments', {
            params: queryParams,
        });
    },
    unassignInstrumentsFromExchangeHostForAssingmentMetadataType(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/remove/exchange-host/instrument', {
            params: queryParams,
        });
    },
    getPollingFrequenciesData(queryParams) {
        return toolsApiAxios.get('/exchange-discovery/get/all-exchange-polling-freq', {
            params: queryParams,
        });
    },
};

export const QADataService = {
    getSpotMarkets(api_key) {
        let params = {};

        if (api_key) params.api_key = api_key;

        return dataApiAxios.get('/spot/v1/markets', {
            params: params,
        });
    },
    geBlobMigrationData(queryParams) {
        return toolsApiAxios.get('/qa-data/get/blob-migration-data', {
            params: queryParams,
        });
    },
    getPollingSpotCandlesData(queryParams) {
        return toolsApiAxios.get('/qa-data/get/polling-spot-candles-data', {
            params: queryParams,
        });
    },
    getPollingSpotCandlesDataByTimestamp(queryParams) {
        return toolsApiAxios.get('/qa-data/get/polling-spot-candles-data-by-timestamp', {
            params: queryParams,
        });
    },
    getPollingStreamingSpotData(queryParams) {
        return toolsApiAxios.get('/qa-data/get/polling-spot-streaming-data', {
            params: queryParams,
        });
    },
};

export const UserManagement = {
    searchEmail(email) {
        return authApiAxios.post('/cryptopian/admin/get/by/email', {
            email: email,
            user_info_sections: ['general'],
        });
    },
    getRecentUsersList(last_user_id, emailPattern) {
        let params = {
            last_user_id: 0,
        };
        if (emailPattern) {
            params.email_pattern = emailPattern;
        }
        if (last_user_id) {
            params.last_user_id = last_user_id;
        }
        return authApiAxios.post('/cryptopian/admin/get/user/list', params);
    },
    searchId(id) {
        return authApiAxios.post('/cryptopian/admin/get', {
            user_id: id,
            user_info_sections: ['general'],
        });
    },
    searchUsername(username) {
        return authApiAxios.post('/cryptopian/search/username', {
            search_query: username,
            limit: 20,
        });
    },
    getUserData(user_id) {
        return authApiAxios.post('/cryptopian/admin/get', {
            user_id: user_id,
            user_info_sections: ['last_modified', 'log', 'general', 'pass', 'security_info', 'source', 'other', 'app_info', 'email_notifications', 'subscription', 'billing_history'],
        });
    },
    getRolesData() {
        return authApiAxios.post('/cryptopian/admin/get/roles');
    },
    getRolesUserCanAlter() {
        return authApiAxios.post('/cryptopian/admin/get/roles/can-alter');
    },
    getPermissionsKeys() {
        return authApiAxios.post('/stats/calls/api/keys');
    },
    getAllUserKeys(user_id) {
        return authApiAxios.post('/cryptopian/get/all-keys-support', {
            user_id: user_id,
        });
    },
    appendToLog(user_id, logText) {
        return authApiAxios.post('/cryptopian/admin/log/append', {
            user_id: user_id,
            text_to_append: logText,
        });
    },
    updateEmail(email, user_id) {
        return authApiAxios.post('/cryptopian/admin/update/user/all', {
            update_data: { general: { email: email } },
            user_id: user_id,
        });
    },
    updateUserDetails(objectToSendToApi, user_id) {
        return authApiAxios.post('/cryptopian/admin/update/user/all', {
            update_data: objectToSendToApi,
            user_id: user_id,
        });
    },
    removeUserSubscription(userId) {
        return authApiAxios.post('/cryptopian/remove/subscription', {
            user_id: userId,
        });
    },
    getAvailableSubscriptionPlans() {
        return authApiAxios.get('/cryptopian/admin/get/plans');
    },
    generateApiKeyForUser(permissions, user_id, key_info) {
        return authApiAxios.post('/cryptopian/create/api/key/support', {
            permissions: permissions,
            user_id: user_id,
            key_info: key_info,
        });
    },
    getUserUsedCalls(userId) {
        return authApiAxios.post('/cryptopian/admin/get/userid/limit', {
            user_id: userId,
        });
    },
    getUserUsedCallsV2(user_id) {
        return dataApiAxios.get('/admin/v1/rate/limit/user', {
            params: {
                user_id: user_id,
            },
        });
    },
    getUserUsedCallsFromToolsAPI(user_id) {
        return toolsApiAxios.get('/admin/v1/rate/limit/all/user', {
            params: {
                user_id: user_id,
            },
        });
    },
    addSubscriptionForUser(startTs, endTs, subPlanId, userId, invoiceUri, currencyCode, totalCost) {
        return authApiAxios.post('/cryptopian/add/subscription', {
            user_id: userId,
            start_ts: startTs,
            end_ts: endTs,
            sub_plan_id: subPlanId,
            invoice_uri: invoiceUri,
            currency_code: currencyCode,
            total: totalCost,
        });
    },
    removeRole(user_id, role) {
        return authApiAxios.post('/cryptopian/remove/role', {
            user_id: user_id,
            role: role,
        });
    },
    addRole(user_id, role) {
        return authApiAxios.post('/cryptopian/add/role', {
            user_id: user_id,
            role: role,
        });
    },
    deleteAllAuthKeys(user_id) {
        return authApiAxios.post('/cryptopian/delete/all-auth-keys-support', {
            user_id: user_id,
        });
    },
    allowUsernameChange(user_id) {
        return authApiAxios.post('/cryptopian/give/username-change', {
            user_id: user_id,
        });
    },
    disable2FA(user_id) {
        return authApiAxios.post('/cryptopian/disable/two-factor', {
            user_id: user_id,
        });
    },
    activateUser(user_id) {
        return authApiAxios.post('/cryptopian/admin/activate/account', {
            user_id: user_id,
        });
    },
    deleteUserSoft(user_id) {
        return authApiAxios.post('/cryptopian/admin/delete/soft', {
            user_id: user_id,
        });
    },
    deleteUserHard(user_id) {
        return authApiAxios.post('/cryptopian/admin/delete/hard', {
            user_id: user_id,
        });
    },
    banUser(user_id, reason) {
        return authApiAxios.post('/cryptopian/ban', {
            user_id: user_id,
            ban_reason: reason,
        });
    },
    unbanUser(user_id) {
        return authApiAxios.post('/cryptopian/un-ban', {
            user_id: user_id,
        });
    },
    getRecentUsersListPassthroughId(lastUserId, emailPattern, requestId) {
        return this.getRecentUsersList(lastUserId, emailPattern).then((response) => {
            return { response: response, id: requestId };
        });
    },
    blockEmailDomain(userEmail) {
        return authApiAxios.post('/cryptopian/add/blocked-domain', {
            full_email: userEmail,
        });
    },
    unblockEmailDomain(userEmail) {
        return authApiAxios.post('/cryptopian/remove/blocked-domain', {
            full_email: userEmail,
        });
    },
    getBlockedEmailDomains() {
        return authApiAxios.post('/cryptopian/list/blocked-domains');
    },
    getClients() {
        return authApiAxios.post('/stats/customers/summary');
    },
    getStreamerV1BlockListDomain(domainToBlock) {
        return authApiAxios.post('/cryptopian/add/blocked-domain-or-ip-streamer-v1', {
            domain_or_ip: domainToBlock,
        });
    },
    removeStreamerV1BlockDomain(domainToUnblock) {
        return authApiAxios.post('/cryptopian/remove/blocked-domain-or-ip-streamer-v1', {
            domain_or_ip: domainToUnblock,
        });
    },
    setNewLimitsForUser(obj) {
        return authApiAxios.post('/cryptopian/admin/change/limit', {
            user_id: obj.user_id,
            key_type: obj.key_type,
            api_type: obj.api_type,
            new_current_day_limit: obj.new_current_day_limit,
            new_current_month_limit: obj.new_current_month_limit,
        });
    },
    setNewLimitsForUserDataApi(obj) {
        return dataApiAxios.get('/admin/v1/rate/limit/user/reset', {
            params: {
                user_id: obj.user_id,
                key_type: obj.key_type,
                api_type: obj.api_type,
                new_current_day_limit: obj.new_current_day_limit,
                new_current_month_limit: obj.new_current_month_limit,
            },
        });
    },
    setNewLimitsForUserToolsApi(obj) {
        return toolsApiAxios.get('/admin/v1/rate/limit/user/reset', {
            params: {
                user_id: obj.user_id,
                key_type: obj.key_type,
                api_type: obj.api_type,
                new_current_day_limit: obj.new_current_day_limit,
                new_current_month_limit: obj.new_current_month_limit,
            },
        });
    },
    setNewLimitsForUserDataStreamer(obj) {
        return dataStreamerApiAxios.get('/admin/v1/rate/limit/user/reset', {
            params: {
                user_id: obj.user_id,
                key_type: obj.key_type,
                api_type: obj.api_type,
                new_current_day_limit: obj.new_current_day_limit,
                new_current_month_limit: obj.new_current_month_limit,
            },
        });
    },
    getCallsByEndpointMinApi (user_id) {
        return minApiAxios.get('/cryptopian/admin/stats/user/endpoint/usage', {
            params: {
                user_id: user_id,
            },
        });
    },
    getAllClients(limit, offset, auth_key) {
        let params = {};
        if (limit) {
            params.limit = limit;
        }
        if (offset) {
            params.offset = offset;
        }
        if (auth_key) {
            params.auth_key = auth_key;
        }
        return authApiAxios.post('/stats/customers/summary', params);
    },
    getApiCallsForUser(user_id, apiType) {
        return authApiAxios.post('/cryptopian/admin/stats/calls/api/keys', {
            user_id: user_id,
            api_type: apiType,
        });
    },
    getApiCallsForUserDataApi(userId) {
        return dataApiAxios.get('/admin/v1/stats/calls/keys', {
            params: {
                user_id: userId,
            },
        });
    },
    getApiCallsForUserMinApi(userId) {
        return minApiAxios.get('/cryptopian/admin/stats/calls/api/keys', {
            params: {
                user_id: userId, 
            },
        });
    },
    getApiCallsForUserToolsApi(userId) {
        return toolsApiAxios.get('/admin/v1/stats/calls/keys', {
            params: {
                user_id: userId,
            },
        });
    },
    getCallsByEndpoint(user_id) {
        return authApiAxios.post('/cryptopian/admin/stats/user/endpoint/usage', {
            user_id: user_id,
        });
    },
    getCallsByEndpointDataApi(user_id) {        
        return dataApiAxios.get('/admin/v1/stats/user/endpoint/usage', {
            params: {
                user_id: user_id,
            },
        });
    },
    getCallsByEndpointToolsApi(user_id) {
        return toolsApiAxios.get('/admin/v1/stats/user/endpoint/usage', {
            params: {
                user_id: user_id,
            },
        });
    },
    getUserByApiKey(api_key) {
        return authApiAxios.post('/cryptopian/admin/get/by/api_key', {
            user_api_key: api_key,
            user_info_sections: ['general'],
        });
    },
    getUsersPortfolios(userId) {
        return authApiAxios.get('/cryptopian/admin/get/portfolios', {
            params: {
                user_id: userId,
                include_deleted: true,
            },
        });
    },
    reverseDeletePortfolio(params) {
        return authApiAxios.get('/cryptopian/admin/reversedelete/portfolio', { params });
    },
    reverseDeleteMemberFromPortfolio(params) {
        return authApiAxios.get('/cryptopian/admin/reversedelete/portfolio/coin', { params });
    },
    supportUpdateBillingRecord(params) {
        return authApiAxios.post('/cryptopian/admin/update/billing/record', params);
    },
};

export const Insights = {
    getStatsAllV1(api_key) {
        let params = {};

        if (api_key) params.api_key = api_key;

        return minApiAxios.get('/stats/global/endpoint/all', {
            params: params,
        });
    },
    getStatsEndpointV1(path, api_key) {
        let params = { path: path };

        if (api_key) params.api_key = api_key;

        return minApiAxios.get('/stats/global/endpoint', {
            params: params,
        });
    },
    getStatsUniqueV1(api_key) {
        let params = {};

        if (api_key) params.api_key = api_key;

        return minApiAxios.get('/stats/global/unique', {
            params: params,
        });
    },
    getStatsAllV2(api_key) {
        let params = {};

        if (api_key) params.api_key = api_key;

        return dataApiAxios.get('/admin/v1/stats/global/endpoint/calls/list', {
            params: params,
        });
    },
    getStatsEndpointV2(path, api_key) {
        let params = { path: path };

        if (api_key) params.api_key = api_key;

        return dataApiAxios.get('/admin/v1/stats/global/endpoint', {
            params: params,
        });
    },
    getStatsUniqueV2(api_key) {
        let params = {};

        if (api_key) params.api_key = api_key;

        return dataApiAxios.get('/admin/v1/stats/global/unique', {
            params: params,
        });
    },
    getStatsAllTools() {
        return toolsApiAxios.get('/admin/v1/stats/global/endpoint/calls/list');
    },
    getStatsEndpointTools(path) {
        let params = { path: path };

        return toolsApiAxios.get('/admin/v1/stats/global/endpoint', {
            params: params,
        });
    },
    getStatsUniqueTools() {
        return toolsApiAxios.get('/admin/v1/stats/global/unique');
    },
};

export const InsightsDataStreamer = {
    getStreamerInsightsOverview(api_key) {
        const params = {};
        if (api_key) {
            params.api_key = api_key
        }
        return toolsApiAxios.get('/streamer-insights/overview', {
            params: params,
        });
    },
    getStreamerInsightsServerStats(api_key, machineHostname) {
        const params = {};
        if (api_key) {
            params.api_key = api_key
        }
        return toolsApiAxios.get(`/streamer-insights/server?hostname=${machineHostname}`, {
            params: params,
        });
    },
    getStreamerSubscriptionTypeMapping(api_key) {
        const params = {};
        if (api_key) {
            params.api_key = api_key
        }
        return dataStreamerApiAxios.get(`/subscriptionTypeMapping`, {
            params: params,
        });
    },
    getStreamerInsightsSubscriptions(api_key, machineHostname, userId) {
        const params = {};
        if (api_key) {
            params.api_key = api_key
        }
        return toolsApiAxios.get(`/streamer-insights/subscriptions?hostname=${machineHostname}&client_id=${userId}`, {
            params: params,
        });
    },
    getStreamerInsightsUsers(api_key) {
        const params = {};
        if (api_key) {
            params.api_key = api_key
        }
        return toolsApiAxios.get(`/streamer-insights/overview/users`, {
            params: params,
        });
    },
    getStreamerInsightsUser(api_key, userId) {
        const params = {};
        if (api_key) {
            params.api_key = api_key
        }
        return toolsApiAxios.get(`/streamer-insights/user?client_id=${userId}`, {
            params: params,
        });
    },
    getStreamerInsightsSubscriptionsOverview(api_key) {
        const params = {};
        if (api_key) {
            params.api_key = api_key
        }
        return toolsApiAxios.get(`/streamer-insights/overview/subscriptions`, {
            params: params,
        });
    }
};

export const CsvDownloader = {
    getMarkets(api_key) {
        let params = {};

        if (api_key) params.api_key = api_key;

        return dataApiAxios.get('/spot/v1/markets/instruments', {
            params: params,
        });
    },
    getMarketInstrumentDataFromTimeRange(api_key, market, instrument, ts) {
        let params = {
            market: market,
            instrument: instrument,
            hour_ts: ts,
            response_format: 'CSV',
        };

        if (api_key) params.api_key = api_key;

        return dataApiAxios.get('/spot/v1/historical/trades/hour', {
            params: params,
        });
    },
};

export const NewsManager = {
    getAllSources() {
        return toolsApiAxios.get('/news/v1/source/all/list');
    },
    getSources(lang, type, status) {
        let params = {
            lang: lang,
            type: type,
            status: status,
        };

        return toolsApiAxios.get('/news/v1/source/list', {
            params: params,
        });
    },
    changeSourceStatus(id, newStatus) {
        let params = {
            id: id,
            status: newStatus,
        };

        const endpoint = newStatus === 'INACTIVE' ? 'disable' : 'enable';

        return toolsApiAxios.post(`/news/v1/source/${endpoint}`, params);
    },
    addSource(data) {
        return toolsApiAxios.post('/news/v1/source/add', data);
    },
    updateSource(data) {
        delete data.isActive;
        for (const d in data) {
            const lowercaseD = d.toLowerCase();
            data[lowercaseD] = data[d];
            delete data[d];
        }
        return toolsApiAxios.post('/news/v1/source/update', data);
    },
    getCategories() {
        return toolsApiAxios.get('news/v1/category/list');
    },
    addCategory(data) {
        return toolsApiAxios.post('news/v1/category/add', data);
    },
    updateCategory(data) {
        return toolsApiAxios.post('news/v1/category/update', data);
    },
    deleteCategory(data) {
        let params = {
            id: data.ID,
            status: 'INACTIVE',
        };
        return toolsApiAxios.post('news/v1/category/delete', params);
    },
    getArticles(apiParams) {
        let params = {};
        if (apiParams.source_ids) params.source_ids = apiParams.source_ids.join(',');
        if (apiParams.article_ids) params.article_ids = apiParams.article_ids.join(',');
        if (apiParams.lang) params.lang = apiParams.lang;
        if (apiParams.categories) params.categories = apiParams.categories.join(',');
        if (apiParams.exclude_categories) params.exclude_categories = apiParams.exclude_categories.join(',');
        if (apiParams.to_ts) params.to_ts = apiParams.to_ts;

        return toolsApiAxios.get('news/v1/article/list', { params: params });
    },
    addArticle(data) {
        if (data.keywords) {
            data.keywords = data.keywords.join(',');
        }
        return toolsApiAxios.post('news/v1/article/add', data);
    },
    updateArticleImageUrl(data) {
        return toolsApiAxios.post('/news/v1/article/image/update', data);
    },
    deleteArticle(id) {
        let params = {
            article_id: id,
            status: 'INACTIVE',
        };
        return toolsApiAxios.post('news/v1/article/delete', params);
    },
    uploadImageForSource(data) {
        return toolsApiAxios.post('/news/v1/source/image/upload', data);
    },
    uploadImageForArticle(data) {
        return toolsApiAxios.post('/news/v1/article/image/upload', data);
    },
};

export const ExchangeDiscoveryService = {
    getSpotMarkets(api_key) {
        let params = {};

        if (api_key) params.api_key = api_key;

        return dataApiAxios.get('/spot/v1/markets', {
            params: params,
        });
    },
    getFuturesMarkets(api_key) {
        let params = {};

        if (api_key) params.api_key = api_key;

        return dataApiAxios.get('/futures/v1/markets', {
            params: params,
        });
    },
    getExchangeHostsWithMetadata(exchangeInternalName) {
        const queryParams = { exchange_internal_name: exchangeInternalName };
        return toolsApiAxios.get('/exchange-discovery/get/exchange-all-hosts-metadata', {
            params: queryParams,
        });
    },
    removeExchangeHostFromExchangeIntegrationHostsList(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/remove/exchange-host', queryParams);
    },
    createExchangeHostMetadata(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/create/exchange-host-metadata', queryParams);
    },
    updateExchangeHostMetadata(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/update/exchange-host-metadata', queryParams);
    },
    removeExchangeHostMetadata(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/remove/exchange-host-metadata', queryParams);
    },
    assignInstrumentsToExchangeHostForAssignmentMetadataType(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/add/exchange-host/instrument', queryParams);
    },
    unassignInstrumentsFromExchangeHostForAssignmentMetadataType(queryParams) {
        return toolsApiAxios.post('/exchange-discovery/remove/exchange-host/instrument', queryParams);
    },
};