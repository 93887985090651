import Vue from 'vue';
import Vuex from 'vuex';
import {
    AuthService
} from "@/common/api.service";
import {
    FETCH_AUTH_USER_LOADING,
    FETCH_AUTH_USER_SUCCESS,
    FETCH_AUTH_USER_ERROR,
    FETCH_LOGOUT_LOADING,
    FETCH_LOGOUT_SUCCESS,
    FETCH_LOGOUT_ERROR
} from './mutations.type';
import {
    FETCH_AUTH_USER,
    FETCH_LOGOUT
} from './actions.type';

Vue.use(Vuex);

const intialValues = {
    user: null,
    loadingAuth: false,
    errorAuth: null
};

const state = {
    ...JSON.parse(JSON.stringify(intialValues))
};


const getters = {
    user(state) {
        return state.user;
    },
    loadingAuth(state) {
        return state.loadingAuth;
    },
    errorAuth(state) {
        return state.errorAuth;
    }
};

const mutations = {
    [FETCH_AUTH_USER_LOADING](state) {
        Vue.set(state, 'loadingAuth', true);
    },
    [FETCH_AUTH_USER_SUCCESS](state, payload) {
        Vue.set(state, 'user', payload);
        Vue.set(state, 'loadingAuth', false);
        Vue.set(state, 'errorAuth', null);
    },
    [FETCH_AUTH_USER_ERROR](state, payload) {
        Vue.set(state, 'user', null);
        Vue.set(state, 'loadingAuth', false);
        Vue.set(state, 'errorAuth', payload);
    },
    [FETCH_LOGOUT_LOADING](state) {
        Vue.set(state, 'loadingAuth', true);
    },
    [FETCH_LOGOUT_SUCCESS](state, payload) {
        Vue.set(state, 'user', payload);
        Vue.set(state, 'loadingAuth', false);
        Vue.set(state, 'errorAuth', null);
    },
    [FETCH_LOGOUT_ERROR](state, payload) {
        Vue.set(state, 'errorAuth', payload);
    },
};

const actions = {
    [FETCH_AUTH_USER]({ commit }) {
        return new Promise((resolve, reject) => {
            AuthService.getAuthUser().then(res => {
                const data = res.data.Data;

                //check for support roles
                if (data.general.roles.includes('customer_support') || data.general.roles.includes('customer_support_manager')) {
                    commit(FETCH_AUTH_USER_SUCCESS, data);
                    resolve(data);
                } else {
                    commit(FETCH_AUTH_USER_ERROR);
                    reject();
                }
            }).catch(err => {
                commit(FETCH_AUTH_USER_ERROR, err);
                reject(err);
            });
        });
    },
    [FETCH_LOGOUT]({ commit }) {
        commit('asd');
    }
};

const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default store;