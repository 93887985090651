<template>
  <div name="snackbars">
    <v-snackbar
      v-model="show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      :top="snackbar.top === true"
      :bottom="snackbar.bottom === true"
      :left="snackbar.left === true"
      :right="snackbar.right === true"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/SHOW_MESSAGE") {
        this.snackbar.text = state.snackbar.text;
        this.snackbar.color = state.snackbar.color;
        this.snackbar.timeout = state.snackbar.timeout;
        this.snackbar.top = state.snackbar.top;
        this.snackbar.bottom = state.snackbar.bottom;
        this.snackbar.left = state.snackbar.left;
        this.snackbar.right = state.snackbar.right;
        this.show = true;
      }
    });
  },
  data() {
    return {
      show: false,
      snackbar: {
        color: "",
        text: "",
        timeout: -1,
        top: false,
        bottom: false,
        left: false,
        right: false,
      },
    };
  },
  computed: {
    ...mapGetters("snackbar", [
      "text",
      "color",
      "timeout",
      "top",
      "bottom",
      "left",
      "right",
    ]),
  },
};
</script>